import React, { useState, useEffect } from "react";
import axios from "axios";
import ToolsContent from "../Components/ToolsContent";
import { useLocation, useNavigate } from "react-router-dom";
import { Beforeunload } from "react-beforeunload";
import logo from "../Assets/bg.jpg";

import {
  AppLayout,
  ContentLayout,
  Header,
  SpaceBetween,
  Container,
  Grid,
  Form,
  FormField,
  Button,
  Input,
  Multiselect,
  RadioGroup,
  Flashbar,
  Icon,
  Box,
  Textarea,
  Modal,
  Table,
} from "@cloudscape-design/components";

function Landing() {
  const navigate = useNavigate();
  document.title = "Hansoftware | Home";
  const [toolsOpen, setToolsOpen] = useState(true);
  const [responseSuccessful, setResponseSuccessful] = useState("");
  const [flashbarSuccess, setFlashbarSuccess] = useState([]);
  const [flashbarFail, setFlashbarFail] = useState([]);
  const [ticketLoading, setTicketLoading] = useState(false);
  const [numTicketsQueue, setNumTicketsQueue] = useState(0);
  const [currentQueueData, setCurrentQueueData] = useState([]);

  const [settingsVisible, setSettingsVisible] = useState(false);
  const [visible, setVisible] = useState(false);

  return (
    <div style={{}}>
      <AppLayout
        headerVariant="high-contrast"
        headerSelector="#top-nav"
        toolsHide
        navigationHide
        //maxContentWidth={10000}
        content={
          <div>
            <Modal
              onDismiss={() => {
                //setTicketSaveLoc(prevSaveLoc);
                setSettingsVisible(false);
              }}
              visible={settingsVisible}
              size="large"
              footer={
                <>
                  <Box float="left">
                    <Button
                      onClick={() => {
                        setSettingsVisible(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>

                  <Box float="right">
                    <Button
                      variant="primary"
                      onClick={() => {
                        setSettingsVisible(false);
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </>
              }
              header="Settings"
            >
              <FormField
                description="Where you want your tickets to save by default."
                label="Ticket Save Location"
              ></FormField>
            </Modal>
            <ContentLayout
              defaultPadding
              //disableOverlap
              headerBackgroundStyle={(mode) =>
                `center center/cover url("${logo}")`
              }
              maxContentWidth={1200}
              header={
                <Header variant="h2">
                  <Container>
                    <Box class="box" padding="xl">
                      <Box fontSize="display-l" fontWeight="bold" variant="h1">
                        Business Administration Platform
                      </Box>
                      <Box fontSize="display-l" fontWeight="light">
                        The tools your business needs, built to your
                        specifications
                      </Box>
                      <Box
                        variant="p"
                        color="text-body-secondary"
                        margin={{ top: "xs", bottom: "l" }}
                      >
                        An all-in-one solution designed to empower small
                        businesses with efficiency, insight, and growth.
                        Streamline your operations effortlessly with tools for
                        invoicing, expense tracking, client management and more.
                        Gain real-time financial clarity with customizable
                        reports and actionable analytics, helping you make
                        informed decisions that drive success.
                      </Box>
                      <Box float="left">
                        <Button variant="primary">Sign up today</Button>
                      </Box>
                      <Box float="right">
                        <Button
                          onClick={() => {
                            navigate("/login");
                          }}
                        >
                          Returning user? Sign in
                        </Button>
                      </Box>
                    </Box>
                  </Container>
                </Header>
              }
            >
              <SpaceBetween direction="vertical" size="s">
                {/* ----- Start Form Containers ----- */}
                <Grid
                  gridDefinition={[
                    { colspan: 6 },
                    { colspan: 6 },
                    { colspan: 6 },
                    { colspan: 6 },
                  ]}
                >
                  <Container
                    fitHeight
                    header={<Header variant="h3">Date and Time</Header>}
                  ></Container>
                  <Container
                    fitHeight
                    header={<Header variant="h3">Plumber Information</Header>}
                  ></Container>
                  <Container
                    fitHeight
                    header={<Header variant="h3">Customer Information</Header>}
                  ></Container>
                  <Container
                    fitHeight
                    header={<Header variant="h3">Notes</Header>}
                  >
                    <SpaceBetween direction="vertical" size="m"></SpaceBetween>
                  </Container>
                </Grid>
              </SpaceBetween>
            </ContentLayout>
          </div>
        }
        tools={ToolsContent.faq}
      />
    </div>
  );
}

export default Landing;
