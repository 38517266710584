import { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Landing from "./Pages/landing";
import Login from "./Pages/login";
import PrivacyPolicy from "./Pages/privacyPolicy";
import JobTickets from "./Pages/jobTickets";
import CustomerList from "./Pages/customerList";
import DashRedirect from "./Pages/dashRedirect";
import NotFound from "./Pages/notFound";

import TopNav from "./Components/TopNav";
import { UserContext } from "./Components/User";

export default function App() {
  const { isLoggedIn } = useContext(UserContext);
  return (
    <div className="App">
      <>
        <TopNav />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/dashboard"
            element={isLoggedIn() ? <DashRedirect /> : <Navigate to="/login" />}
          >
            <Route path="job-tickets" element={<JobTickets />} />
            <Route path="customer-list" element={<CustomerList />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </>
    </div>
  );
}
