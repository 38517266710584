import React from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Dashboard from "./dashboard";

export default function DashRedirect() {
  const loc = useLocation();
  if (loc.pathname === "/dashboard" || loc.pathname === "/dashboard/") {
    return <Dashboard />;
  }
  return <Outlet />;
}
