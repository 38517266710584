import * as React from "react";
import { Button, Header, Table, Icon } from "@cloudscape-design/components";
import HelpPanel from "@cloudscape-design/components/help-panel";

export default {
  faq: (
    <HelpPanel
      header={<h2>User Guide: Job Ticket Generator</h2>}
      footer={
        <div>
          <h3>
            Troubleshooting guide
          </h3>
          <h4>Before you email the IT department, did you:</h4>
          <ol>
            <li>
              Make sure the data you're entering is valid for the input?
            </li>
            <li>
              Experiment with whatever you're working with?
            </li>
            <li>
              Check your internet connection?
            </li>
            <li>
              Restart the program?
            </li>
            <li>
              Restart your computer?
            </li>
          </ol>
          <p>If you have tried your best to solve your issue on your own, you may email the IT department using the button below.</p>
          <Button
                  href={
                    "mailto:hansoftwaredev@gmail.com?subject=CPS%20Troubleshooting%20Report"
                  }
                >
                  <b>Send report</b>
                </Button>
        </div>
      }
    >
      <div>
        <p>
          Welcome to the Castle Plumbing Services Job Ticket Creator. Please read the following information
          in order to fully utilize the ticket creator.
        </p>

        <h3>In this guide</h3>
        <ul>
          <li>Inputs</li>
          <li>Constraints and validation</li>
          <li>Creating a ticket</li>
          <li>Ticket queue</li>
        </ul>

        <h4>Inputs</h4>
        <p>
          Each category of information contains input boxes, grouped with similar content. To use these input boxes,
          click in the blank area. You'll then see a blinking cursor (|). You can now type whatever information
          you want as labeled above the input.
        </p>
        <p>
          Underneath some of the input boxes show examples of what the provided information should look like.
          Use this information to make sure your input is valid.
        </p>
        <p>
          Under the "Plumber Information" category, you will see two unfamiliar inputs. The former is a radio button selection for the payment type.
          Just click the inside of the circle of the value you want to select. The latter is a button dropdown,
          which allows you to select multiple plumbers from a list. Simply click anywhere in the box, and
          then click the plumber(s) you want added to the job.
        </p>

        <h4>Constraints and validation</h4>
        <p>
          Each input has certain constraints placed on it. This is to ensure the information input is valid for
          use on a job ticket. These constraints will only appear if your input is invalid. For example, if you
          were to put more than 10 digits on a phone number, the program will invalidate your input and ask you to
          check your information. You will not be able to create a ticket or add it to your queue unless you fix 
          the errors present.
        </p>
        <p>
          Not all inputs are required. The following is a list of the items required in order to add a ticket to the queue.
          Note that some of these will be disabled depending on the job type, so obviously these are not required if said
          job type is selected.
          <ul>
            <li>Call date</li>
            <li>Scheduled job date</li>
            <li>Time frame (ETA)</li>
            <li>Job type</li>
            <li>Plumber(s) assigned</li>
            <li>Customer name</li>
            <li>Address</li>
            <li>Zip code</li>
            <li>Phone number</li>
          </ul>
        </p>
        <p>
          Some things cannot be required that are <b>VERY</b> important, such as gate code. Obviously, not every customer has
          a gate, so requiring the gate code to be input is impossible.
        </p>
        <p>
          The job notes and office comments have special "warning" constraints instead of errors like the other inputs.
          These will be yellow in color, and note that the input is not invalid, rather, your final ticket may look
          different based on the length of the text you input. The current soft-limit is set at a length where the text
          is Goldilocks, not too large, not too small. Feel free to experiment!
        </p>
        <p>
          This same situation will actually occur on all inputs of the job ticket. That is, you can make your input text
          as long as you see fit; however, the font size on the ticket will be reduced depending on how far past the original
          character count you go. The opposite is also true: the shorter the text, the more space it will fill on the ticket.
          You may notice this the most with the dates on the ticket.
        </p>

        <h4>Creating a ticket</h4>
        <p>
          Utilize the previous information about inputs and validation to fill in a customer's information.
          Alternatively, search the customer in the Customer List first, then use the "autofill" feature to make your
          job much easier. When using the autofill feature, be sure to confirm correct information such as name spelling,
          address, etc.
        </p>
        <p>
          Once your customer information is filled in, enter your other job ticket information. Once satisfied,
          click the "Add ticket to queue" button to temporarily save the job ticket. You may then proceed
          to filling out another job ticket as necessary.
        </p>
        <p>
          Once you click "Complete ticket queue", a green bar will appear at the bottom of the screen, notifying you
          that the tickets have been finished. Clicking the "View tickets" button will open the PDFs for you directly,
          allowing you to print or email as needed. By default, the tickets are saved to "C:\Users\[username]\Documents\CPS Job Tickets",
          but this is customizable by using the Settings button at the top of the page.
        </p>
        <p>
          In the case that a red bar appears instead of a green bar, you may need to send a report to
          the IT department using the button provided in the notification. The button is already set up to include
          the error, but it is recommended to provide details on what exactly you were doing in the email.
        </p>

        <h4>Ticket queue</h4>
        <p>
          In order to add a ticket to the queue, simply input your job ticket data, then click the "Add ticket to queue"
          button. Make sure that all of your inputs are valid, otherwise you will not be able to add the ticket to the
          queue.
        </p>
        <p>
          Job ticket information will be stored in a queue where, once you click "Complete ticket queue", will be entered
          into a PDF document, allowing you to print and email them as you previously have.
          Job ticket information is stored across sessions. That is, in the case that you accidentally close the page,
          refresh, or anything else, your job ticket queue is saved until you click "Complete ticket queue".
        </p>
        <p>
          In order to reload any queue you may have "lost" in an accidentally closing or refresh of the program,
          you can use the "Reload previous queue" button on the bottom right of the screen. A green flashbar will
          show you if the reload was successful, and will tell you how many tickets are in the queue. Keep in mind
          that once you reload the queue, any tickets you add will be added to the previous queue, and will not be
          added to a separate queue.
        </p>
        <p>
          To start a new queue, you must click the "Complete ticket queue" button to clear the current queue. Once
          the ticket(s) is/are completed, a new queue is started.
        </p>
      </div>
    </HelpPanel>
  ),
  customerList: (
    <HelpPanel
    footer={
      <div>
        <h2>
          Troubleshooting guide
        </h2>
        <h4>Before you email the IT department, did you:</h4>
        <ol>
          <li>
            Make sure the data you're entering is valid for the input?
          </li>
          <li>
            Experiment with whatever you're working with?
          </li>
          <li>
            Check your internet connection?
          </li>
          <li>
            Restart the program?
          </li>
          <li>
            Restart your computer?
          </li>
        </ol>
        <p>If you have tried your best to solve your issue on your own, you may email the IT department using the button below.</p>
      </div>
    }>
        <p style={{fontSize: 16}}>
          Welcome to the Customer List. Please read the following information
          in order to fully utilize the software.
        </p>

        <Table
            header={<Header>Key</Header>}
            variant="borderless"
            contentDensity="compact"
            columnDefinitions={[
              {
                id: "color",
                header: "Color",
                cell: (item) =>
                  item.color === "Red" ? (
                    <b style={{ color: "red" }}>{item.color}</b>
                  ) : item.color === "Blue" ? (
                    <i style={{ color: "blue" }}>{item.color}</i>
                  ) : item.color === "Arrow" ? (
                    <>
                      {item.color} <Icon name="caret-right-filled" />
                    </>
                  ) : (
                    item.color
                  ),
                isRowHeader: true,
              },
              {
                id: "desc",
                header: "Description",
                cell: (item) => item.color === "Red" ? (
                  <b style={{ color: "red" }}>{item.desc}</b>
                ) : item.color === "Blue" ? (
                  <i style={{ color: "blue" }}>{item.desc}</i>
                ) : (
                  item.desc
                ),
              },
            ]}
            items={[
              {
                color: "Red",
                desc: "Black list customer",
              },
              {
                color: "Blue",
                desc: "Gray list customer",
              },
              {
                color: "Arrow",
                desc: "Customer has multiple addresses. Click on the arrow to view them.",
              },
              {
                color: "Customers w/o phone #",
                desc: "Contact the IT department to  edit any information for these customers",
              },
            ]}
            sortingDisabled
          />

        <h3>In this guide</h3>
        <ul>
          <li>Searching for a customer</li>
          <li>Using a customer's information</li>
          <li>Creating a new customer</li>
          <li>Editing customer information</li>
          <li>Special notes</li>
        </ul>

        <h4>Searching for a customer</h4>
        <p>
          Near the top of the page, you'll see a box with a magnifying glass and the words "Search customers".
          Click anywhere inside the box to start typing. You can search any information you're looking for,
          such as customer name, address, city, phone number, or even certain words within the customer notes.
        </p>
        <p>
          As you start typing, you will see the number of matches to the right of the search box.
          Those matches will appear in the table and every other customer will disappear temporarily.
          In order to clear the filter, simply click the "X" in the search box, or remove the text in the search box.
        </p>

        <h4>Using a customer's information</h4>
        <p>
          Each customer has their own information as seen by the columns at the top of the table. This information
          is able to be used on the Job Ticket Generator very easily. Simply find the customer who's information
          you want to use, then click the "Autofill" button on the right side of the screen, within said customer's
          row.
        </p>
        <p>
          Using the Autofill feature will redirect you to the Job Ticket Generator page with all information from this customer.
          In the case that more information is wanted for the customer, please read the final section of this guide
          to learn how to edit customer information.
        </p>

        <h4>Creating a new customer</h4>
        <p>
          To create a new customer, please click the "Create customer" button on the top of the page.
          This will show a popup with input fields for the customer's information. Similar to the Job Ticket page,
          these inputs have certain requirements based on the type of information.
        </p>
        <p>
          Simply fill in the customer's information, click the "Create customer" button, and wait for the green notification bar
          to appear. In the case it is taking more than 30 seconds, or a red bar appears instead, please follow the basic
          troubleshooting guide at the bottom of each page. Once you see the green success bar, you must click the "refresh" icon
          at the top of the page for the new customer to show up.
        </p>

        <h4>Editing customer information</h4>
        <p>
          You may edit most of the information of a customer. In order to do this, click the "Edit table" button 
          to switch the table to Edit mode. Note the different title (added "Editing"), the loading icon next
          to the "Stop editing" button, and the pencil icon on the columns to confirm you are in edit mode.
        </p>
        <p>
          To edit information, hover over the cell you'd like to change. If the cell can be edited, you will
          see a blue outline with a pencil icon within the cell. If you cannot, you will see a lock icon that
          specifies why you cannot edit it. To edit the cell, simply left-click the information to prompt the input field.
          From here, you can remove the current text and type new, or add to the text in the input. Once satisfied,
          click the checkmark to confirm it. If you want to undo these changes, click the X instead.
        </p>
        <p>
          Once you are finished making your changes, you may click the "Stop editing" button. This will show you all of the
          changes you've made in a bold green text. You can review this information to make sure it is correct,
          and by clicking the "Return to Customer List" button, you may go back and adjust this information as needed.

          To cancel any changes you've made, click the "Cancel changes" button. This will revert all changes back to their
          original value.
          Finally, to save your edited information, be sure to click the "Confirm changes" button. You must do this otherwise
          your changes will be lost!
        </p>
        <p>
          You may notice that you are not able to delete a customer entirely. This is by design in order to avoid accidental deletions.
          If you notice a duplicate customer or something else wrong with a set of data, please email the IT team with an accurate description
          of the item to be deleted, as well as the reasoning to do so. This is necessary as once deleted, this data cannot be retrieved again.
        </p>

        <h4>Special notes</h4>
        <p>
          Certain functions are not accessible while others are occurring. For example, you cannot create a customer while in Edit mode.
          Similarly, you cannot use the Autofill function, nor view the FAQ while in Edit mode.
        </p>
        <p>
          If you have any questions, please make sure to experiment! We have a daily and weekly backup of the customer list, so any changes made can always
          be reverted. <b>Don't be afraid to try things!</b> As a last resort, you may send an email to the IT department using the "Send report" button below.
        </p>
      </HelpPanel>
  ),
};
