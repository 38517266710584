import { CognitoIdentityProviderClient } from "@aws-sdk/client-cognito-identity-provider";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";

const region = "us-east-2";
const userPoolID = "us-east-2_byb26dntm";
const clientID = "kkqv7mp3507hkiqs6332imbil";

const cognitoClient = new CognitoIdentityProviderClient({
  region: region,
});

export { cognitoClient, userPoolID, clientID };
