import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { Beforeunload } from "react-beforeunload";

import {
  Header,
  SpaceBetween,
  Container,
  Button,
  Input,
  Flashbar,
  Table,
  Box,
  TextFilter,
  Icon,
  Modal,
  Spinner,
  FormField,
  Pagination,
  Select,
} from "@cloudscape-design/components";

import ToolsContent from "../Components/ToolsContent";

function CustomerList() {
  const navigate = useNavigate();
  const [responseSuccessful, setResponseSuccessful] = useState("");
  const [flashbarSuccess, setFlashbarSuccess] = useState([]);
  const [flashbarFail, setFlashbarFail] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [tableDataLoading, setTableDataLoading] = useState(true);
  const [lastRefresh, setLastRefresh] = useState(new Date());

  const [expandedItems, setExpandedItems] = useState();
  const [editTable, setEditTable] = useState(false);

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [unitNum, setUnitNum] = useState("");
  const [addressNotes, setAddressNotes] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [community, setCommunity] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [secondaryNum, setSecondaryNum] = useState("");
  const [phoneNotes, setPhoneNotes] = useState("");
  const [email, setEmail] = useState("");
  const [gateCode, setGateCode] = useState("");
  const [notes, setNotes] = useState("");

  const [additionalRows, setAdditionalRows] = useState([]);
  const [childrenObjArr, setChildrenObjArr] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [zeroNumbers, setZeroNumbers] = useState([]);

  const [addAddressData, setAddAddressData] = useState([]);

  const [addressChild, setAddressChild] = useState("");
  const [unitNumChild, setUnitNumChild] = useState("");
  const [addressNotesChild, setAddressNotesChild] = useState("");
  const [cityChild, setCityChild] = useState("");
  const [zipChild, setZipChild] = useState("");
  const [communityChild, setCommunityChild] = useState("");
  const [gateCodeChild, setGateCodeChild] = useState("");

  const [visible, setVisible] = useState(false);
  const [newCustVisible, setNewCustVisible] = useState(false);
  const [secondAddressVisible, setSecondAddressVisible] = useState(false);
  const [addAddressVisible, setAddAddressVisible] = useState(false);

  const [editedItems, setEditedItems] = useState([]);
  const [editedColumns, setEditedColumns] = useState([]);
  const [editedValues, setEditedValues] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [editedExpandedItems, setEditedExpandedItems] = useState();

  const errorMap = new Map();
  const INVALID_INPUT = "Commas (,) may only be used in a customer name";

  const handleSubmit = async (originalRow, column, value) => {
    setSubmitting(true);
    let originalTable = tableData;
    const editedRow = { ...originalRow, [column.id]: value };

    // validate phone number column
    if (column.id === "phone_number" && !value.match(/^\d{3}-\d{3}-\d{4}$/)) {
      errorMap.set(originalRow, "Phone number must be in 000-000-0000 format");
      throw new Error("Inline error");
    }

    // validate zip code column
    if (column.id === "zip" && !value.match(/^\d{5}$/)) {
      errorMap.set(originalRow, "Zip code must be 5 digits exactly");
      throw new Error("Inline error");
    }

    // determine if item is child for specific editing
    let rowIndex = 0;
    if (originalRow.childIndex) {
      rowIndex = Number(originalRow.childIndex);
    }

    if (originalRow.isChild === false) {
      // row can be normal or parent
      // update TableData so the edited value is reflected to user
      setTableData(
        originalTable.map((item) => (item === originalRow ? editedRow : item))
      );
      // before pushing, need to check if row already exists
      let found = false;
      for (let i = 0; i < editedItems.length; i++) {
        if (JSON.stringify(editedItems[i]) === JSON.stringify(originalRow)) {
          found = true;
          break;
        }
      }
      if (!found) {
        if (column.id === "phone_number") {
          editedItems.push({
            ...editedRow,
            ["prevPhone"]: originalRow.phone_number,
          });
        } else {
          editedItems.push(editedRow);
        }
      }
    } else if (originalRow.isChild) {
      // need to get parent object so we can edit it
      // iterate over tableData searching for customer name
      for (let row in tableData) {
        if (originalRow.parentName === tableData[row].customer_name) {
          let parent = tableData[row];
          // update parent object
          parent.children[rowIndex - 1] = editedRow;
          // update TableData so the edited value is reflected to user
          setTableData(
            originalTable.map((item) => (item === originalRow ? parent : item))
          );
          // add item to editedItems so edits can actually take place
          editedItems.push(parent);
        }
      }
    }
    // always push column id and value to use in "changes" modal
    editedColumns.push(column.id);
    editedValues.push(value);
    setSubmitting(false);
  };

  const putEditItems = async (editedItems) => {
    await axios
      .post("/putEditItems", {
        queue: editedItems,
      })
      .then((response) => {
        //console.log(response.data);
        if (response.data.status === 200) {
          setFlashbarSuccess([
            {
              header: "Table updated successfully",
              type: "success",
              dismissible: true,
              dismissLabel: "Dismiss message",
              onDismiss: () => {
                setFlashbarSuccess([]);
                setResponseSuccessful("");
              },
            },
          ]);
          setResponseSuccessful(true);
          dismiss(10000);
        } else {
          setFlashbarFail([
            {
              header: "Failed to update table",
              type: "error",
              content:
                response.data.err +
                " Click the button to the right to send a report to the IT Staff.",
              action: (
                <Button
                  href={
                    "mailto:hansoftwaredev@gmail.com?subject=CPS%20Error%20Report&body=Update%20Table%20" +
                    response.data.err
                  }
                >
                  Send report
                </Button>
              ),
              dismissible: true,
              dismissLabel: "Dismiss message",
              onDismiss: () => {
                setFlashbarFail([]);
                setResponseSuccessful("");
              },
            },
          ]);
          setResponseSuccessful(false);
        }
        setEditedItems([]);
        setEditedColumns([]);
        setEditedValues([]);
        setVisible(false);
        setEditTable(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function getZeroNumberCount() {
    var highestNumber = -1;
    for (var i = 0; i < zeroNumbers.length; i++) {
      // Extract the numerical part after the last hyphen
      var numberPart = zeroNumbers[i].split("-")[2];
      var number = parseInt(numberPart, 10);
      if (number > highestNumber) {
        highestNumber = number;
      }
    }
    return "000-000-0" + highestNumber;
  }

  const addCustomer = async () => {
    if (!phoneNumbers.includes(phoneNum)) {
      let phone_number = phoneNum;
      if (phoneNum === "") {
        phone_number = getZeroNumberCount();
      }
      // combine all customer data into single key-value object
      let customer = {};
      if (additionalRows.length > 0) {
        // need to add to children array of objects
        customer = {
          phone_number: phone_number,
          address_notes: addressNotes,
          billing_address: billingAddress,
          city: city,
          community: community,
          customer_name: name,
          email: email,
          gate: gateCode,
          gray_black_list: "",
          isChild: false,
          lsd: "",
          notes: notes,
          parentId: null,
          phone_info: phoneNotes,
          plumber: "",
          secondary_phone: secondaryNum,
          service_address: address,
          unit: unitNum,
          zip: zip,
          children: childrenObjArr,
        };
      } else {
        customer = {
          phone_number: phone_number,
          address_notes: addressNotes,
          billing_address: billingAddress,
          city: city,
          community: community,
          customer_name: name,
          email: email,
          gate: gateCode,
          gray_black_list: "",
          isChild: false,
          lsd: "",
          notes: notes,
          parentId: null,
          phone_info: phoneNotes,
          plumber: "",
          secondary_phone: secondaryNum,
          service_address: address,
          unit: unitNum,
          zip: zip,
        };
      }
      await axios
        .post("/addCustomer", {
          customer: customer,
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.status === 200) {
            setNewCustVisible(false);
            setFlashbarSuccess([
              {
                header: "Customer added successfully",
                type: "success",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: () => {
                  setFlashbarSuccess([]);
                  setResponseSuccessful("");
                },
              },
            ]);
            setResponseSuccessful(true);
            dismiss(10000);
          } else {
            setNewCustVisible(false);
            setFlashbarFail([
              {
                header: "Failed to add customer",
                type: "error",
                content:
                  response.data.err +
                  " Click the button to the right to send a report to the IT Staff.",
                action: (
                  <Button
                    href={
                      "mailto:hansoftwaredev@gmail.com?subject=CPS%20Error%20Report&body=Add%20Customer%20" +
                      response.data.err
                    }
                  >
                    Send report
                  </Button>
                ),
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: () => {
                  setFlashbarFail([]);
                  setResponseSuccessful("");
                },
              },
            ]);
            setResponseSuccessful(false);
          }
          setPhoneNum("");
          setAddressNotes("");
          setBillingAddress("");
          setCity("");
          setCommunity("");
          setName("");
          setEmail("");
          setGateCode("");
          setNotes("");
          setPhoneNotes("");
          setSecondaryNum("");
          setAddress("");
          setUnitNum("");
          setZip("");
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // phone number already in use
      setFlashbarFail([
        {
          header: "Failed to add customer",
          type: "error",
          content:
            "The phone number used is already in use by another customer. You may create " +
            "the customer again, but this time leave the phone number input blank, and use " +
            "the phone number you have in the secondary number field.",
          dismissible: true,
          dismissLabel: "Dismiss message",
          onDismiss: () => {
            setFlashbarFail([]);
            setResponseSuccessful("");
          },
        },
      ]);
    }
  };

  const addAddress = async (newAddress) => {
    var customer = addAddressData;
    // add children to this object
    if ("children" in customer) {
      // need to append newAddress to current children array
      let children = customer.children;
      children.push(newAddress);
      customer.children = children;
    } else {
      // can put newAddress in directly
      customer.children = [newAddress];
    }
    await axios
      .post("/addAddress", {
        customer: customer,
      })
      .then((response) => {
        //console.log(response.data);
        if (response.data.status === 200) {
          setAddAddressVisible(false);
          setFlashbarSuccess([
            {
              header: "Added address to customer successfully",
              type: "success",
              dismissible: true,
              dismissLabel: "Dismiss message",
              onDismiss: () => {
                setFlashbarSuccess([]);
                setResponseSuccessful("");
              },
            },
          ]);
          setResponseSuccessful(true);
          dismiss(10000);
        } else {
          setAddAddressVisible(false);
          setFlashbarFail([
            {
              header: "Failed to add address to customer",
              type: "error",
              content:
                response.data.err +
                " Click the button to the right to send a report to the IT Staff.",
              action: (
                <Button
                  href={
                    "mailto:hansoftwaredev@gmail.com?subject=CPS%20Error%20Report&body=Add%20Address%20" +
                    response.data.err
                  }
                >
                  Send report
                </Button>
              ),
              dismissible: true,
              dismissLabel: "Dismiss message",
              onDismiss: () => {
                setFlashbarFail([]);
                setResponseSuccessful("");
              },
            },
          ]);
          setResponseSuccessful(false);
        }
        setPhoneNum("");
        setAddressNotes("");
        setBillingAddress("");
        setCity("");
        setCommunity("");
        setName("");
        setEmail("");
        setGateCode("");
        setNotes("");
        setPhoneNotes("");
        setSecondaryNum("");
        setAddress("");
        setUnitNum("");
        setZip("");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function formatDate(date) {
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const dayName = dayNames[date.getDay()];
    const monthName = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    // Get 12-hour time format
    let hours = date.getHours();
    const meridiem = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const formattedDate = `${dayName} ${monthName} ${day} ${year}, ${hours}:${minutes} ${meridiem}`;
    return formattedDate;
  }

  useEffect(() => {
    getCustList();
  }, []);

  const getCustList = async () => {
    setTableDataLoading(true);
    await axios
      .get(
        "https://7bs13kg7ee.execute-api.us-east-1.amazonaws.com/stage_test/getCustomers"
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setTableData(response.data.itemArray);
          setTableDataLoading(false);
          setZeroNumbers(response.data.zeroNumbers);
        } else {
          setFlashbarFail([
            {
              header: "Failed to load customer list",
              type: "error",
              content:
                response.data.err +
                " Click the button to the right to send a report to the IT Staff.",
              action: (
                <Button
                  href={
                    "mailto:hansoftwaredev@gmail.com?subject=CPS%20Error%20Report&body=Customer%20List%20" +
                    response.data.err
                  }
                >
                  Send report
                </Button>
              ),
              dismissible: true,
              dismissLabel: "Dismiss message",
              onDismiss: () => {
                setFlashbarFail([]);
                setResponseSuccessful("");
              },
            },
          ]);
          setTableDataLoading(false);
          setResponseSuccessful(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  function dismiss(length) {
    setTimeout(function () {
      setFlashbarSuccess([]);
      setResponseSuccessful("");
    }, length);
  }

  function EmptyState({ title, subtitle, action }) {
    return (
      <Box textAlign="center" color="inherit">
        <Box variant="strong" textAlign="center" color="inherit">
          {title}
        </Box>
        <Box variant="p" padding={{ bottom: "s" }} color="inherit">
          {subtitle}
        </Box>
        {action}
      </Box>
    );
  }

  const paginationLabels = {
    nextPageLabel: "Next page",
    pageLabel: (pageNumber) => `Go to page ${pageNumber}`,
    previousPageLabel: "Previous page",
  };

  const {
    items: rootItems,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(tableData, {
    filtering: {
      empty: (
        <EmptyState
          title="Error loading database"
          action={
            <Button
              onClick={() => {
                getCustList();
              }}
            >
              Try again
            </Button>
          }
        />
      ),
      noMatch: (
        <EmptyState
          title="No matches found"
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear search filter
            </Button>
          }
        />
      ),
    },
    expandableRows: {
      getId: (item) => item.id,
      getParentId: (item) => item.parentId,
    },
    sorting: {},
    pagination: { pageSize: 150 },
  });

  const { items: editedRootItems } = useCollection(editedItems, {
    filtering: {
      empty: <EmptyState title="Error loading changes" />,
      noMatch: <EmptyState title="No changes made" />,
    },
    expandableRows: {
      getId: (item) => item.id,
      getParentId: (item) => item.parentId,
    },
    sorting: {},
  });

  const closeServer = () => {
    axios.post("/closeServer").catch((error) => {
      console.error(error);
    });
  };

  return editTable === false ? (
    <>
      <Beforeunload onBeforeunload={closeServer} />
      <Modal
        onDismiss={() => {
          setPhoneNum("");
          setAddressNotes("");
          setBillingAddress("");
          setCity("");
          setCommunity("");
          setName("");
          setEmail("");
          setGateCode("");
          setNotes("");
          setPhoneNotes("");
          setSecondaryNum("");
          setAddress("");
          setUnitNum("");
          setZip("");
          setAddressNotesChild("");
          setCityChild("");
          setCommunityChild("");
          setGateCodeChild("");
          setAddressChild("");
          setUnitNumChild("");
          setZipChild("");
          setAdditionalRows([]);
          setChildrenObjArr([]);
          setNewCustVisible(false);
        }}
        visible={newCustVisible}
        size="large"
        footer={
          <>
            <Box float="left">
              <Button
                variant="link"
                onClick={() => {
                  setPhoneNum("");
                  setAddressNotes("");
                  setBillingAddress("");
                  setCity("");
                  setCommunity("");
                  setName("");
                  setEmail("");
                  setGateCode("");
                  setNotes("");
                  setPhoneNotes("");
                  setSecondaryNum("");
                  setAddress("");
                  setUnitNum("");
                  setZip("");
                  setAddressNotesChild("");
                  setCityChild("");
                  setCommunityChild("");
                  setGateCodeChild("");
                  setAddressChild("");
                  setUnitNumChild("");
                  setZipChild("");
                  setAdditionalRows([]);
                  setChildrenObjArr([]);
                  setNewCustVisible(false);
                }}
              >
                Cancel
              </Button>
            </Box>

            <Box float="right">
              <SpaceBetween direction="horizontal" size="s">
                <Button
                  onClick={() => {
                    if (additionalRows.length > 0) {
                      // push children useStates to array of objects
                      let index = additionalRows.length;
                      let obj = {
                        phone_number: "",
                        address_notes: addressNotesChild,
                        city: cityChild,
                        community: communityChild,
                        customer_name: name + "-" + index,
                        email: "",
                        gate: gateCodeChild,
                        isChild: true,
                        lsd: "",
                        notes: "",
                        parentId: "1",
                        service_address: addressChild,
                        unit: unitNumChild,
                        zip: zipChild,
                        parentName: name,
                        childIndex: index,
                      };
                      setChildrenObjArr([...childrenObjArr, obj]);
                      // now reset useStates for next additional address
                      setAddressNotesChild("");
                      setCityChild("");
                      setCommunityChild("");
                      setGateCodeChild("");
                      setAddressChild("");
                      setUnitNumChild("");
                      setZipChild("");
                    }
                    setAdditionalRows([...additionalRows, "row"]);
                    setSecondAddressVisible(true);
                  }}
                >
                  Add additional address
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    if (additionalRows.length > 0) {
                      // push children useStates to array of objects
                      let index = additionalRows.length;
                      let obj = {
                        phone_number: "",
                        address_notes: addressNotesChild,
                        city: cityChild,
                        community: communityChild,
                        customer_name: name + "-" + index,
                        email: "",
                        gate: gateCodeChild,
                        isChild: true,
                        lsd: "",
                        notes: "",
                        parentId: "1",
                        service_address: addressChild,
                        unit: unitNumChild,
                        zip: zipChild,
                        parentName: name,
                        childIndex: index,
                      };
                      setChildrenObjArr([...childrenObjArr, obj]);
                    }
                    addCustomer();
                  }}
                >
                  Add customer
                </Button>
              </SpaceBetween>
            </Box>
          </>
        }
        header="Add customer"
      >
        <SpaceBetween direction="vertical" size="m">
          <FormField label="Customer name" stretch>
            <Input
              onChange={({ detail }) => setName(detail.value)}
              value={name}
              inputMode="text"
            />
          </FormField>
          <FormField label="Service address" stretch>
            <Input
              onChange={({ detail }) => setAddress(detail.value)}
              value={address}
            />
          </FormField>
          <FormField label="Unit #" stretch>
            <Input
              onChange={({ detail }) => setUnitNum(detail.value)}
              value={unitNum}
            />
          </FormField>
          <FormField
            label="Address notes"
            stretch
            constraintText="Home, rental, etc."
          >
            <Input
              onChange={({ detail }) => setAddressNotes(detail.value)}
              value={addressNotes}
            />
          </FormField>
          <FormField label="City" stretch>
            <Input
              onChange={({ detail }) => setCity(detail.value)}
              value={city}
            />
          </FormField>
          <FormField
            label="Zip code"
            stretch
            errorText={
              zip.length > 1 && zip.length !== 5
                ? "Incorrect length, Confirm correct zip code"
                : zip.length > 0 && !zip.match(/^\d+$/)
                ? "Confirm zip code contains numbers only"
                : ""
            }
          >
            <Input
              onChange={({ detail }) => setZip(detail.value)}
              value={zip}
            />
          </FormField>
          <FormField
            label="Community, building, etc."
            constraintText="Island Walk, Dorchester, etc."
            stretch
          >
            <Input
              onChange={({ detail }) => setCommunity(detail.value)}
              value={community}
            />
          </FormField>
          <FormField
            label="Billing address"
            constraintText="Example: 12345 67th St, Chicago, IL, 09876"
            stretch
          >
            <Input
              onChange={({ detail }) => setBillingAddress(detail.value)}
              value={billingAddress}
            />
          </FormField>
          <FormField
            label="Phone number"
            constraintText="Example: 239-455-2295"
            stretch
            errorText={
              phoneNum.length > 12
                ? "Too many characters. Please confirm correct phone number"
                : phoneNum.length > 0 &&
                  !phoneNum.match(/^\d{3}[-]\d{3}[-]\d{4}$/)
                ? "Confirm phone number contains numbers and dashes only"
                : phoneNumbers.includes(phoneNum)
                ? "Phone number is already assigned! You can leave this field blank and use the secondary number input if necessary."
                : ""
            }
          >
            <Input
              onChange={({ detail }) => setPhoneNum(detail.value)}
              value={phoneNum}
              inputMode="tel"
            />
          </FormField>
          <FormField
            label="Secondary number"
            constraintText="Example: 123-456-7890"
            stretch
            errorText={
              secondaryNum.length > 12
                ? "Too many characters. Please confirm correct phone number"
                : secondaryNum.length > 0 &&
                  !secondaryNum.match(/^\d{3}[-]\d{3}[-]\d{4}$/)
                ? "Confirm phone number contains numbers and dashes only"
                : ""
            }
          >
            <Input
              onChange={({ detail }) => setSecondaryNum(detail.value)}
              value={secondaryNum}
              inputMode="tel"
            />
          </FormField>
          <FormField
            label="Phone notes"
            constraintText="Example: Second Customer Name, Cell, Home, Northern, etc."
            stretch
          >
            <Input
              onChange={({ detail }) => setPhoneNotes(detail.value)}
              value={phoneNotes}
            />
          </FormField>
          <FormField
            label="Email address"
            constraintText="Example: michaelknight@gmail.com"
            stretch
            errorText={
              email.length > 0 && !validateEmail(email)
                ? "Please make sure email address is correct."
                : ""
            }
          >
            <Input
              onChange={({ detail }) => setEmail(detail.value)}
              value={email}
              inputMode="email"
            />
          </FormField>
          {email.length > 0 ? (
            <SpaceBetween direction="horizontal" size="s">
              <Button onClick={() => setEmail(email + "@gmail.com")}>
                @gmail.com
              </Button>
              <Button onClick={() => setEmail(email + "@yahoo.com")}>
                @yahoo.com
              </Button>
              <Button onClick={() => setEmail(email + "@hotmail.com")}>
                @hotmail.com
              </Button>
              <Button onClick={() => setEmail(email + "@comcast.net")}>
                @comcast.net
              </Button>
              <Button onClick={() => setEmail(email + "@outlook.com")}>
                @outlook.com
              </Button>
            </SpaceBetween>
          ) : (
            ""
          )}
          <FormField label="Gate code" stretch>
            <Input
              onChange={({ detail }) => setGateCode(detail.value)}
              value={gateCode}
            />
          </FormField>
          <FormField label="Notes" stretch>
            <Input
              onChange={({ detail }) => setNotes(detail.value)}
              value={notes}
            />
          </FormField>
        </SpaceBetween>
        {secondAddressVisible
          ? additionalRows.map((row, index) => (
              <>
                <h2>Additional address {index + 1}</h2>
                <SpaceBetween direction="vertical" size="m">
                  <FormField label="Service address" stretch>
                    <Input
                      onChange={({ detail }) => setAddressChild(detail.value)}
                      value={
                        childrenObjArr[index]
                          ? childrenObjArr[index].service_address
                          : addressChild
                      }
                      disabled={childrenObjArr[index]}
                    />
                  </FormField>
                  <FormField label="Unit #" stretch>
                    <Input
                      onChange={({ detail }) => setUnitNumChild(detail.value)}
                      value={
                        childrenObjArr[index]
                          ? childrenObjArr[index].unit
                          : unitNumChild
                      }
                      disabled={childrenObjArr[index]}
                    />
                  </FormField>
                  <FormField
                    label="Address notes"
                    stretch
                    constraintText="Home, rental, etc."
                  >
                    <Input
                      onChange={({ detail }) =>
                        setAddressNotesChild(detail.value)
                      }
                      value={
                        childrenObjArr[index]
                          ? childrenObjArr[index].address_notes
                          : addressNotesChild
                      }
                      disabled={childrenObjArr[index]}
                    />
                  </FormField>
                  <FormField label="City" stretch>
                    <Input
                      onChange={({ detail }) => setCityChild(detail.value)}
                      value={
                        childrenObjArr[index]
                          ? childrenObjArr[index].city
                          : cityChild
                      }
                      disabled={childrenObjArr[index]}
                    />
                  </FormField>
                  <FormField
                    label="Zip code"
                    stretch
                    errorText={
                      zipChild.length > 1 && zipChild.length !== 5
                        ? "Incorrect length, Confirm correct zip code"
                        : zipChild.length > 0 && !zipChild.match(/^\d+$/)
                        ? "Confirm zip code contains numbers only"
                        : ""
                    }
                  >
                    <Input
                      onChange={({ detail }) => setZipChild(detail.value)}
                      value={
                        childrenObjArr[index]
                          ? childrenObjArr[index].zip
                          : zipChild
                      }
                      disabled={childrenObjArr[index]}
                    />
                  </FormField>
                  <FormField
                    label="Community, building, etc."
                    constraintText="Island Walk, Dorchester, etc."
                    stretch
                  >
                    <Input
                      onChange={({ detail }) => setCommunityChild(detail.value)}
                      value={
                        childrenObjArr[index]
                          ? childrenObjArr[index].community
                          : communityChild
                      }
                      disabled={childrenObjArr[index]}
                    />
                  </FormField>
                  <FormField label="Gate code" stretch>
                    <Input
                      onChange={({ detail }) => setGateCodeChild(detail.value)}
                      value={
                        childrenObjArr[index]
                          ? childrenObjArr[index].gate
                          : gateCodeChild
                      }
                      disabled={childrenObjArr[index]}
                    />
                  </FormField>
                </SpaceBetween>
              </>
            ))
          : ""}
      </Modal>
      <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        size="large"
        footer={
          <>
            <Box float="left">
              <Button href="mailto:hansoftwaredev@gmail.com?subject=CPS%20Troubleshooting%20Report">
                Send report
              </Button>
            </Box>

            <Box float="right">
              <Button variant="primary" onClick={() => setVisible(false)}>
                Return to Customer List
              </Button>
            </Box>
          </>
        }
        header="User Guide: Customer List"
      >
        {ToolsContent.customerList}
      </Modal>
      <Table
        {...collectionProps}
        items={rootItems}
        loadingText="Loading customers"
        loading={tableDataLoading}
        stripedRows
        stickyHeader
        stickyColumns={{ first: 1, last: 1 }}
        contentDensity="comfortable"
        resizableColumns={true}
        trackBy="customer_name"
        variant="container"
        pagination={
          <Pagination {...paginationProps} ariaLabels={paginationLabels} />
        }
        filter={
          <TextFilter
            {...filterProps}
            countText={
              filteredItemsCount === 1
                ? `1 match`
                : `${filteredItemsCount} matches`
            }
            filteringPlaceholder="Search customers"
            filteringAriaLabel="Search customers"
          />
        }
        expandableRows={{
          getItemChildren: (item) => item.children,
          isItemExpandable: (item) => Boolean(item.children),
          expandedItems: expandedItems,
          onExpandableItemToggle: ({ detail }) =>
            setExpandedItems((prev) => {
              const next = new Set(
                (prev ?? []).map((item) => item.customer_name)
              );
              detail.expanded
                ? next.add(detail.item.customer_name)
                : next.delete(detail.item.customer_name);
              return [...next].map((customer_name) => ({ customer_name }));
            }),
        }}
        expandedItems={expandedItems}
        columnDefinitions={[
          {
            id: "customer_name",
            header: "Customer name",
            cell: (e) =>
              e.parentId === null ? (
                e.gray_black_list === "gray" ? (
                  <div style={{ color: "#0022FF", fontStyle: "italic" }}>
                    {e.customer_name}
                  </div>
                ) : e.gray_black_list === "black" ? (
                  <div style={{ color: "red", fontStyle: "italic" }}>
                    {e.customer_name}
                  </div>
                ) : (
                  e.customer_name
                )
              ) : (
                ""
              ),
            width: 250,
            minWidth: 250,
            sortingField: "customer_name",
            isRowHeader: true,
          },
          {
            id: "service_address",
            header: "Service address",
            width: 200,
            minWidth: 200,
            cell: (e) => e.service_address,
          },
          {
            id: "unit",
            header: "Unit",
            width: 80,
            minWidth: 80,
            cell: (e) => e.unit,
          },
          {
            id: "address_notes",
            header: "Address notes",
            width: 100,
            minWidth: 100,
            cell: (e) => e.address_notes,
          },
          {
            id: "city",
            header: "City",
            width: 100,
            minWidth: 100,
            cell: (e) => e.city,
            sortingField: "city",
          },
          {
            id: "zip",
            header: "Zip",
            width: 80,
            minWidth: 80,
            cell: (e) => e.zip,
          },
          {
            id: "community",
            header: "Community",
            width: 180,
            minWidth: 100,
            cell: (e) => e.community,
            sortingField: "community",
          },
          {
            id: "billing_address",
            header: "Billing address",
            width: 80,
            minWidth: 20,
            cell: (e) => e.billing_address,
          },
          {
            id: "phone_number",
            header: "Phone number",
            width: 150,
            minWidth: 50,
            cell: (e) =>
              e.phone_number.match(/^0{3}-0{3}-0{1}\d{3}$/) || e.isChild
                ? ""
                : e.phone_number,
          },
          {
            id: "secondary_phone",
            header: "Secondary #",
            width: 150,
            minWidth: 50,
            cell: (e) => e.secondary_phone,
          },
          {
            id: "phone_info",
            header: "Phone info",
            width: 100,
            minWidth: 20,
            cell: (e) => e.phone_info,
          },
          {
            id: "email",
            header: "Email",
            width: 150,
            minWidth: 20,
            cell: (e) => (e.isChild ? "" : e.email),
          },
          {
            id: "gate",
            header: "Gate",
            width: 100,
            minWidth: 20,
            cell: (e) => e.gate,
          },
          {
            id: "lsd",
            header: "Last service date",
            width: 100,
            minWidth: 20,
            cell: (e) => e.lsd,
            sortingField: "lsd",
          },
          {
            id: "plumber",
            header: "Plumber",
            width: 100,
            minWidth: 20,
            cell: (e) => e.plumber,
            sortingField: "plumber",
          },
          {
            id: "notes",
            header: "Notes",
            width: 600,
            minWidth: 200,
            cell: (e) => e.notes,
          },
          {
            id: "autofill",
            header: "Autofill",
            width: 100,
            minWidth: 100,
            cell: (e) => (
              <Button
                variant="inline-link"
                onClick={() => {
                  // send to Job Ticket page with e as autofill info
                  navigate("/", {
                    state: {
                      e,
                    },
                  });
                }}
              >
                Autofill
              </Button>
            ),
          },
        ]}
        header={
          <Container
            header={
              <Header
                variant="h1"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <SpaceBetween
                      direction="horizontal"
                      size="xs"
                      alignItems="center"
                    >
                      {lastRefresh && (
                        <Box
                          variant="p"
                          fontSize="body-s"
                          padding="n"
                          color="text-status-inactive"
                          textAlign="right"
                        >
                          <span aria-live="polite" aria-atomic="true">
                            Last updated
                            <br />
                            {formatDate(lastRefresh)}
                          </span>
                        </Box>
                      )}
                      <Button
                        iconName="refresh"
                        ariaLabel="Refresh"
                        loadingText="Refreshing table content"
                        loading={tableDataLoading}
                        onClick={() => {
                          getCustList();
                          setLastRefresh(new Date());
                        }}
                        disabled={tableDataLoading}
                      />
                    </SpaceBetween>
                    <Button
                      onClick={() => {
                        setVisible(true); // open FAQ
                      }}
                    >
                      View FAQ
                    </Button>
                    <Button
                      onClick={() => {
                        // set phone number array
                        var phoneNumArr = [];
                        for (let row in tableData) {
                          let phoneNumber = tableData[row].phone_number;
                          phoneNumArr.push(phoneNumber);
                        }
                        setPhoneNumbers(phoneNumArr);
                        setNewCustVisible(true);
                      }}
                    >
                      Add new customer
                    </Button>
                    <Button
                      onClick={() => {
                        setEditTable(true);
                      }}
                    >
                      Edit table
                    </Button>
                    <Button variant="primary" onClick={() => navigate("/")}>
                      Job Ticket Generator &nbsp;
                      <Icon name="caret-right-filled" />
                    </Button>
                  </SpaceBetween>
                }
              >
                Customer List
              </Header>
            }
          >
            <SpaceBetween direction="vertical" size="s">
              Click the "View FAQ" button to the right to learn how to utilize
              this Customer List
              {responseSuccessful === true ? (
                <Flashbar items={flashbarSuccess} />
              ) : (
                ""
              )}
              {responseSuccessful === false ? (
                <Flashbar items={flashbarFail} />
              ) : (
                ""
              )}
            </SpaceBetween>
          </Container>
        }
      />
    </>
  ) : (
    <>
      <Beforeunload onBeforeunload={closeServer} />
      <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        size="max"
        footer={
          <>
            <Box float="left">
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={() => setVisible(false)}>
                  Return to Customer List (continue editing)
                </Button>
                <Button
                  onClick={() => {
                    // reset all edited useStates
                    setEditedItems([]);
                    setEditedColumns([]);
                    setEditedValues([]);
                    setVisible(false);
                    setEditTable(false);
                  }}
                >
                  Cancel changes
                </Button>
              </SpaceBetween>
            </Box>

            <Box float="right">
              <Button
                variant="primary"
                onClick={() => {
                  putEditItems(editedItems);
                }}
              >
                Confirm changes
              </Button>
            </Box>
          </>
        }
        header="Confirm changes"
      >
        <SpaceBetween direction="vertical" size="s">
          <Box variant="p" padding={{ bottom: "s" }} color="inherit">
            Please confirm your changes to the Customer List below. <br />
            <br />
            <Flashbar
              items={[
                {
                  type: "info",
                  content: (
                    <>
                      NOTE: returning to the customer list will allow you to
                      continue editing information. Cancelling changes will lose
                      all data you've entered. Confirming changes will save the
                      new edited data to the database.
                    </>
                  ),
                  id: "message_1",
                },
              ]}
            />
          </Box>
          <Table
            {...collectionProps}
            items={editedItems ? editedRootItems : []}
            loadingText="Loading customers"
            stripedRows
            stickyHeader
            contentDensity="compact"
            resizableColumns={true}
            trackBy="customer_name"
            variant="container"
            expandableRows={{
              getItemChildren: (item) => item.children,
              isItemExpandable: (item) => Boolean(item.children),
              expandedItems: editedExpandedItems,
              onExpandableItemToggle: ({ detail }) =>
                setEditedExpandedItems((prev) => {
                  const next = new Set(
                    (prev ?? []).map((item) => item.customer_name)
                  );
                  detail.expanded
                    ? next.add(detail.item.customer_name)
                    : next.delete(detail.item.customer_name);
                  return [...next].map((customer_name) => ({ customer_name }));
                }),
            }}
            expandedItems={editedExpandedItems}
            // in column definitions, we need to highlight or otherwise note that the item has been edited
            // Do this by making sure the column is within editedColumns, then see if e.columnName is equal to editedValues
            columnDefinitions={[
              {
                id: "gray_black_list",
                header: "G/B",
                width: 80,
                minWidth: 80,
                cell: (e) =>
                  editedColumns.includes("gray_black_list") &&
                  editedValues.includes(e.gray_black_list) ? (
                    <div style={{ color: "#2ea043", fontWeight: "bold" }}>
                      {e.gray_black_list}
                    </div>
                  ) : (
                    e.gray_black_list
                  ),
              },
              {
                id: "customer_name",
                header: "Customer name",
                cell: (e) =>
                  editedColumns.includes("customer_name") &&
                  editedValues.includes(e.customer_name) ? (
                    <div style={{ color: "#2ea043", fontWeight: "bold" }}>
                      {e.parentId === null ? (
                        e.gray_black_list === "gray" ? (
                          <div
                            style={{ color: "#0022FF", fontStyle: "italic" }}
                          >
                            {e.customer_name}
                          </div>
                        ) : e.gray_black_list === "black" ? (
                          <div style={{ color: "red", fontStyle: "italic" }}>
                            {e.customer_name}
                          </div>
                        ) : (
                          e.customer_name
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  ) : e.parentId === null ? (
                    e.gray_black_list === "gray" ? (
                      <div style={{ color: "#0022FF", fontStyle: "italic" }}>
                        {e.customer_name}
                      </div>
                    ) : e.gray_black_list === "black" ? (
                      <div style={{ color: "red", fontStyle: "italic" }}>
                        {e.customer_name}
                      </div>
                    ) : (
                      e.customer_name
                    )
                  ) : (
                    ""
                  ),

                width: 250,
                minWidth: 250,
                sortingField: "customer_name",
                isRowHeader: true,
              },
              {
                id: "service_address",
                header: "Service address",
                width: 200,
                minWidth: 200,
                cell: (e) =>
                  editedColumns.includes("service_address") &&
                  editedValues.includes(e.service_address) ? (
                    <div style={{ color: "#2ea043", fontWeight: "bold" }}>
                      {e.service_address}
                    </div>
                  ) : (
                    e.service_address
                  ),
              },
              {
                id: "unit",
                header: "Unit",
                width: 80,
                minWidth: 80,
                cell: (e) =>
                  editedColumns.includes("unit") &&
                  editedValues.includes(e.unit.toString()) ? (
                    <div style={{ color: "#2ea043", fontWeight: "bold" }}>
                      {e.unit}
                    </div>
                  ) : (
                    e.unit
                  ),
              },
              {
                id: "address_notes",
                header: "Address notes",
                width: 100,
                minWidth: 100,
                cell: (e) =>
                  editedColumns.includes("address_notes") &&
                  editedValues.includes(e.address_notes) ? (
                    <div style={{ color: "#2ea043", fontWeight: "bold" }}>
                      {e.address_notes}
                    </div>
                  ) : (
                    e.address_notes
                  ),
              },
              {
                id: "city",
                header: "City",
                width: 100,
                minWidth: 100,
                cell: (e) =>
                  editedColumns.includes("city") &&
                  editedValues.includes(e.city) ? (
                    <div style={{ color: "#2ea043", fontWeight: "bold" }}>
                      {e.city}
                    </div>
                  ) : (
                    e.city
                  ),
                sortingField: "city",
              },
              {
                id: "zip",
                header: "Zip",
                width: 80,
                minWidth: 80,
                cell: (e) =>
                  editedColumns.includes("zip") &&
                  editedValues.includes(e.zip) ? (
                    <div style={{ color: "#2ea043", fontWeight: "bold" }}>
                      {e.zip}
                    </div>
                  ) : (
                    e.zip
                  ),
              },
              {
                id: "community",
                header: "Community",
                width: 180,
                minWidth: 100,
                cell: (e) =>
                  editedColumns.includes("community") &&
                  editedValues.includes(e.community) ? (
                    <div style={{ color: "#2ea043", fontWeight: "bold" }}>
                      {e.community}
                    </div>
                  ) : (
                    e.community
                  ),
              },
              {
                id: "billing_address",
                header: "Billing address",
                width: 80,
                minWidth: 20,
                cell: (e) =>
                  editedColumns.includes("billing_address") &&
                  editedValues.includes(e.billing_address) ? (
                    <div style={{ color: "#2ea043", fontWeight: "bold" }}>
                      {e.billing_address}
                    </div>
                  ) : (
                    e.billing_address
                  ),
              },
              {
                id: "phone_number",
                header: "Phone number",
                width: 150,
                minWidth: 50,
                cell: (e) =>
                  editedColumns.includes("phone_number") &&
                  editedValues.includes(e.phone_number) ? (
                    <div style={{ color: "#2ea043", fontWeight: "bold" }}>
                      {e.phone_number}
                    </div>
                  ) : (
                    e.phone_number
                  ),
              },
              {
                id: "secondary_phone",
                header: "Secondary #",
                width: 150,
                minWidth: 50,
                cell: (e) =>
                  editedColumns.includes("secondary_phone") &&
                  editedValues.includes(e.secondary_phone) ? (
                    <div style={{ color: "#2ea043", fontWeight: "bold" }}>
                      {e.secondary_phone}
                    </div>
                  ) : (
                    e.secondary_phone
                  ),
              },
              {
                id: "phone_info",
                header: "Phone info",
                width: 100,
                minWidth: 20,
                cell: (e) =>
                  editedColumns.includes("phone_info") &&
                  editedValues.includes(e.phone_info) ? (
                    <div style={{ color: "#2ea043", fontWeight: "bold" }}>
                      {e.phone_info}
                    </div>
                  ) : (
                    e.phone_info
                  ),
              },
              {
                id: "email",
                header: "Email",
                width: 150,
                minWidth: 20,
                cell: (e) =>
                  editedColumns.includes("email") &&
                  editedValues.includes(e.email) ? (
                    <div style={{ color: "#2ea043", fontWeight: "bold" }}>
                      {e.email}
                    </div>
                  ) : (
                    e.email
                  ),
              },
              {
                id: "gate",
                header: "Gate",
                width: 100,
                minWidth: 20,
                cell: (e) =>
                  editedColumns.includes("gate") &&
                  editedValues.includes(e.gate) ? (
                    <div style={{ color: "#2ea043", fontWeight: "bold" }}>
                      {e.gate}
                    </div>
                  ) : (
                    e.gate
                  ),
              },
              {
                id: "lsd",
                header: "Last service date",
                width: 100,
                minWidth: 20,
                cell: (e) =>
                  editedColumns.includes("lsd") &&
                  editedValues.includes(e.lsd) ? (
                    <div style={{ color: "#2ea043", fontWeight: "bold" }}>
                      {e.lsd}
                    </div>
                  ) : (
                    e.lsd
                  ),
              },
              {
                id: "plumber",
                header: "Plumber",
                width: 100,
                minWidth: 20,
                cell: (e) =>
                  editedColumns.includes("plumber") &&
                  editedValues.includes(e.plumber) ? (
                    <div style={{ color: "#2ea043", fontWeight: "bold" }}>
                      {e.plumber}
                    </div>
                  ) : (
                    e.plumber
                  ),
              },
              {
                id: "notes",
                header: "Notes",
                width: 600,
                minWidth: 200,
                cell: (e) =>
                  editedColumns.includes("notes") &&
                  editedValues.includes(e.notes) ? (
                    <div style={{ color: "#2ea043", fontWeight: "bold" }}>
                      {e.notes}
                    </div>
                  ) : (
                    e.notes
                  ),
              },
            ]}
            header={<Header>Unsaved changes</Header>}
          />
        </SpaceBetween>
      </Modal>
      <Modal
        onDismiss={() => {
          setPhoneNum("");
          setAddressNotes("");
          setBillingAddress("");
          setCity("");
          setCommunity("");
          setName("");
          setEmail("");
          setGateCode("");
          setNotes("");
          setPhoneNotes("");
          setSecondaryNum("");
          setAddress("");
          setUnitNum("");
          setZip("");
          setAddAddressVisible(false);
        }}
        visible={addAddressVisible}
        size="large"
        footer={
          <>
            <Box float="left">
              <Button
                variant="link"
                onClick={() => {
                  setPhoneNum("");
                  setAddressNotes("");
                  setBillingAddress("");
                  setCity("");
                  setCommunity("");
                  setName("");
                  setEmail("");
                  setGateCode("");
                  setNotes("");
                  setPhoneNotes("");
                  setSecondaryNum("");
                  setAddress("");
                  setUnitNum("");
                  setZip("");
                  setAddressNotesChild("");
                  setCityChild("");
                  setCommunityChild("");
                  setGateCodeChild("");
                  setAddressChild("");
                  setUnitNumChild("");
                  setZipChild("");
                  setAddAddressVisible(false);
                }}
              >
                Cancel
              </Button>
            </Box>

            <Box float="right">
              <SpaceBetween direction="horizontal" size="s">
                <Button
                  variant="primary"
                  onClick={() => {
                    var index = 1;
                    if ("children" in addAddressData) {
                      var children = addAddressData.children;
                      for (var child in children) {
                        var origIndex = child.childIndex;
                        if (origIndex > index) {
                          index = origIndex;
                        } else if (origIndex === index) {
                          index = Number(origIndex) + 1;
                        }
                      }
                    }
                    let obj = {
                      phone_number: "",
                      address_notes: addressNotes,
                      city: city,
                      community: community,
                      customer_name: addAddressData.customer_name + "-" + index,
                      email: "",
                      gate: gateCode,
                      isChild: true,
                      lsd: "",
                      notes: "",
                      parentId: "1",
                      service_address: address,
                      unit: unitNum,
                      zip: zip,
                      parentName: addAddressData.customer_name,
                      childIndex: index,
                    };
                    addAddress(obj);
                  }}
                >
                  Add address
                </Button>
              </SpaceBetween>
            </Box>
          </>
        }
        header="Add address"
      >
        <SpaceBetween direction="vertical" size="m">
          <FormField label="Customer name" stretch>
            <Input
              disabled
              value={addAddressData.customer_name}
              inputMode="text"
            />
          </FormField>
          <FormField label="Service address" stretch>
            <Input
              onChange={({ detail }) => setAddress(detail.value)}
              value={address}
            />
          </FormField>
          <FormField label="Unit #" stretch>
            <Input
              onChange={({ detail }) => setUnitNum(detail.value)}
              value={unitNum}
            />
          </FormField>
          <FormField
            label="Address notes"
            stretch
            constraintText="Home, rental, etc."
          >
            <Input
              onChange={({ detail }) => setAddressNotes(detail.value)}
              value={addressNotes}
            />
          </FormField>
          <FormField label="City" stretch>
            <Input
              onChange={({ detail }) => setCity(detail.value)}
              value={city}
            />
          </FormField>
          <FormField
            label="Zip code"
            stretch
            errorText={
              zip.length > 1 && zip.length !== 5
                ? "Incorrect length, Confirm correct zip code"
                : zip.length > 0 && !zip.match(/^\d+$/)
                ? "Confirm zip code contains numbers only"
                : ""
            }
          >
            <Input
              onChange={({ detail }) => setZip(detail.value)}
              value={zip}
            />
          </FormField>
          <FormField
            label="Community, building, etc."
            constraintText="Island Walk, Dorchester, etc."
            stretch
          >
            <Input
              onChange={({ detail }) => setCommunity(detail.value)}
              value={community}
            />
          </FormField>
          <FormField
            label="Billing address"
            constraintText="Example: 12345 67th St, Chicago, IL, 09876"
            stretch
          >
            <Input disabled value={addAddressData.billing_address} />
          </FormField>
          <FormField
            label="Phone number"
            constraintText="Example: 239-455-2295"
            stretch
            errorText={
              phoneNum.length > 12
                ? "Too many characters. Please confirm correct phone number"
                : phoneNum.length > 0 &&
                  !phoneNum.match(/^[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{4}$/)
                ? "Confirm phone number contains numbers and dashes only"
                : phoneNumbers.includes(phoneNum)
                ? "Phone number is already assigned! You can leave this field blank and use the secondary number input if necessary."
                : ""
            }
          >
            <Input
              disabled
              value={addAddressData.phone_number}
              inputMode="tel"
            />
          </FormField>
          <FormField
            label="Secondary number"
            constraintText="Example: 123-456-7890"
            stretch
            errorText={
              secondaryNum.length > 12
                ? "Too many characters. Please confirm correct phone number"
                : secondaryNum.length > 0 &&
                  !secondaryNum.match(/^[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{4}$/)
                ? "Confirm phone number contains numbers and dashes only"
                : ""
            }
          >
            <Input
              disabled
              value={addAddressData.secondary_phone}
              inputMode="tel"
            />
          </FormField>
          <FormField
            label="Phone notes"
            constraintText="Example: Second Customer Name, Cell, Home, Northern, etc."
            stretch
          >
            <Input disabled value={addAddressData.phone_info} />
          </FormField>
          <FormField
            label="Email address"
            constraintText="Example: michaelknight@gmail.com"
            stretch
            errorText={
              email.length > 0 && !validateEmail(email)
                ? "Please make sure email address is correct."
                : ""
            }
          >
            <Input disabled value={addAddressData.email} inputMode="email" />
          </FormField>
          <FormField label="Gate code" stretch>
            <Input
              onChange={({ detail }) => setGateCode(detail.value)}
              value={gateCode}
            />
          </FormField>
          <FormField label="Notes" stretch>
            <Input disabled value={addAddressData.notes} />
          </FormField>
        </SpaceBetween>
      </Modal>
      <Table
        ariaLabels={{
          activateEditLabel: (column, item) =>
            `Edit ${item.customer_name} ${column.header}`,
          cancelEditLabel: (column) => `Cancel editing ${column.header}`,
          submitEditLabel: (column) => `Submit editing ${column.header}`,
          tableLabel: "Table with inline editing",
        }}
        {...collectionProps}
        items={rootItems}
        loadingText="Loading customers"
        loading={tableDataLoading}
        submitEdit={handleSubmit}
        stripedRows
        stickyHeader
        stickyColumns={{ first: 3, last: 1 }}
        resizableColumns={true}
        trackBy="customer_name"
        variant="container"
        pagination={
          <Pagination
            {...paginationProps}
            disabled={submitting}
            ariaLabels={paginationLabels}
          />
        }
        enableKeyboardNavigation
        filter={
          <TextFilter
            {...filterProps}
            countText={
              filteredItemsCount === 1
                ? `1 match`
                : `${filteredItemsCount} matches`
            }
            filteringPlaceholder="Search customers"
            filteringAriaLabel="Search customers"
            disabled={submitting}
          />
        }
        expandableRows={{
          getItemChildren: (item) => item.children,
          isItemExpandable: (item) => Boolean(item.children),
          expandedItems: expandedItems,
          onExpandableItemToggle: ({ detail }) =>
            setExpandedItems((prev) => {
              const next = new Set(
                (prev ?? []).map((item) => item.customer_name)
              );
              detail.expanded
                ? next.add(detail.item.customer_name)
                : next.delete(detail.item.customer_name);
              return [...next].map((customer_name) => ({ customer_name }));
            }),
        }}
        expandedItems={expandedItems}
        columnDefinitions={[
          // first column is a work-around for first column not being editable
          {
            id: "*",
            header: "*",
            width: 20,
            maxWidth: 20,
            cell: (e) => "",
          },
          {
            id: "gray_black_list",
            header: "G/B",
            width: 120,
            minWidth: 80,
            cell: (e) => e.gray_black_list,
            editConfig: {
              ariaLabel: "G/B",
              editIconAriaLabel: "editable",
              errorIconAriaLabel: "G/B Error",
              editingCell: (item, { currentValue, setValue }) => {
                const value = currentValue ?? item.gray_black_list;
                return (
                  <Select
                    autoFocus={true}
                    expandToViewport={true}
                    selectedOption={
                      [
                        { label: "None", value: "" },
                        { label: "Gray", value: "gray" },
                        { label: "Black", value: "black" },
                      ].find((option) => option.value === value) ?? null
                    }
                    onChange={(event) => {
                      setValue(
                        event.detail.selectedOption.value ??
                          item.gray_black_list
                      );
                    }}
                    options={[
                      { label: "None", value: "" },
                      { label: "Gray", value: "gray" },
                      { label: "Black", value: "black" },
                    ]}
                  />
                );
              },
              disabledReason: (item) => {
                // disable if item is a child
                if (item.isChild) {
                  return "You cannot change this value. Change on the parent row instead.";
                }
                return undefined;
              },
            },
          },
          {
            id: "customer_name",
            header: "Customer name",
            width: 300,
            minWidth: 300,
            sortingField: "customer_name",
            isRowHeader: true,
            cell: (e) =>
              e.gray_black_list === "gray" ? (
                <div style={{ color: "#0022FF", fontStyle: "italic" }}>
                  {e.customer_name}
                </div>
              ) : e.gray_black_list === "black" ? (
                <div style={{ color: "red", fontStyle: "italic" }}>
                  {e.customer_name}
                </div>
              ) : (
                e.customer_name
              ),
            editConfig: {
              ariaLabel: "Customer name",
              editIconAriaLabel: "editable",
              errorIconAriaLabel: "Name Error",
              editingCell: (item, { currentValue, setValue }) => {
                return (
                  <Input
                    autoFocus={true}
                    value={currentValue ?? item.customer_name}
                    onChange={(event) => setValue(event.detail.value)}
                  />
                );
              },
              disabledReason: (item) => {
                if (item.isChild) {
                  return "You cannot change this value. Change on the parent row instead.";
                }
                return undefined;
              },
            },
          },
          {
            id: "service_address",
            header: "Service address",
            width: 250,
            minWidth: 250,
            cell: (e) => e.service_address,
            editConfig: {
              ariaLabel: "Service address",
              editIconAriaLabel: "editable",
              errorIconAriaLabel: "Address Error",
              validation(item, value) {
                if (errorMap.has(item)) {
                  if (value) {
                    errorMap.set(
                      item,
                      value.indexOf(",") > -1 ? INVALID_INPUT : undefined
                    );
                  }
                  return errorMap.get(item);
                }
              },
              editingCell: (item, { currentValue, setValue }) => {
                return (
                  <Input
                    autoFocus={true}
                    value={currentValue ?? item.service_address}
                    onChange={(event) => setValue(event.detail.value)}
                  />
                );
              },
            },
          },
          {
            id: "unit",
            header: "Unit",
            width: 120,
            minWidth: 120,
            cell: (e) => e.unit,
            editConfig: {
              ariaLabel: "Unit",
              editIconAriaLabel: "editable",
              errorIconAriaLabel: "Unit Error",
              validation(item, value) {
                if (errorMap.has(item)) {
                  if (value) {
                    errorMap.set(
                      item,
                      value.indexOf(",") > -1 ? INVALID_INPUT : undefined
                    );
                  }
                  return errorMap.get(item);
                }
              },
              editingCell: (item, { currentValue, setValue }) => {
                return (
                  <Input
                    autoFocus={true}
                    value={currentValue ?? item.unit}
                    onChange={(event) => setValue(event.detail.value)}
                  />
                );
              },
            },
          },
          {
            id: "address_notes",
            header: "Address notes",
            width: 150,
            minWidth: 150,
            cell: (e) => e.address_notes,
            editConfig: {
              ariaLabel: "Address notes",
              editIconAriaLabel: "editable",
              errorIconAriaLabel: "Address Notes Error",
              validation(item, value) {
                if (errorMap.has(item)) {
                  if (value) {
                    errorMap.set(
                      item,
                      value.indexOf(",") > -1 ? INVALID_INPUT : undefined
                    );
                  }
                  return errorMap.get(item);
                }
              },
              editingCell: (item, { currentValue, setValue }) => {
                return (
                  <Input
                    autoFocus={true}
                    value={currentValue ?? item.address_notes}
                    onChange={(event) => setValue(event.detail.value)}
                  />
                );
              },
            },
          },
          {
            id: "city",
            header: "City",
            width: 150,
            minWidth: 150,
            cell: (e) => e.city,
            editConfig: {
              ariaLabel: "City",
              editIconAriaLabel: "editable",
              errorIconAriaLabel: "City Error",
              validation(item, value) {
                if (errorMap.has(item)) {
                  if (value) {
                    errorMap.set(
                      item,
                      value.indexOf(",") > -1 ? INVALID_INPUT : undefined
                    );
                  }
                  return errorMap.get(item);
                }
              },
              editingCell: (item, { currentValue, setValue }) => {
                return (
                  <Input
                    autoFocus={true}
                    value={currentValue ?? item.city}
                    onChange={(event) => setValue(event.detail.value)}
                  />
                );
              },
            },
          },
          {
            id: "zip",
            header: "Zip",
            width: 120,
            minWidth: 120,
            cell: (e) => e.zip,
            editConfig: {
              ariaLabel: "Zip",
              editIconAriaLabel: "editable",
              errorIconAriaLabel: "Zip Error",
              validation(item, value) {
                if (errorMap.has(item)) {
                  if (value) {
                    errorMap.set(
                      item,
                      value.indexOf(",") > -1 ? INVALID_INPUT : undefined
                    );
                  }
                  return errorMap.get(item);
                }
              },
              editingCell: (item, { currentValue, setValue }) => {
                return (
                  <Input
                    autoFocus={true}
                    value={currentValue ?? item.zip}
                    onChange={(event) => setValue(event.detail.value)}
                  />
                );
              },
            },
          },
          {
            id: "community",
            header: "Community",
            width: 180,
            minWidth: 180,
            cell: (e) => e.community,
            editConfig: {
              ariaLabel: "Community",
              editIconAriaLabel: "editable",
              errorIconAriaLabel: "Community Error",
              validation(item, value) {
                if (errorMap.has(item)) {
                  if (value) {
                    errorMap.set(
                      item,
                      value.indexOf(",") > -1 ? INVALID_INPUT : undefined
                    );
                  }
                  return errorMap.get(item);
                }
              },
              editingCell: (item, { currentValue, setValue }) => {
                return (
                  <Input
                    autoFocus={true}
                    value={currentValue ?? item.community}
                    onChange={(event) => setValue(event.detail.value)}
                  />
                );
              },
            },
          },
          {
            id: "billing_address",
            header: "Billing address",
            width: 100,
            minWidth: 20,
            cell: (e) => e.billing_address,
            editConfig: {
              ariaLabel: "Billing address",
              editIconAriaLabel: "editable",
              errorIconAriaLabel: "Billing address Error",
              editingCell: (item, { currentValue, setValue }) => {
                return (
                  <Input
                    autoFocus={true}
                    value={currentValue ?? item.billing_address}
                    onChange={(event) => setValue(event.detail.value)}
                  />
                );
              },
              disabledReason: (item) => {
                if (item.isChild) {
                  return "You cannot change this value. Change on the parent row instead.";
                }
                return undefined;
              },
            },
          },
          {
            id: "phone_number",
            header: "Phone number",
            width: 150,
            minWidth: 50,
            cell: (e) =>
              e.phone_number.match(/^0{3}-0{3}-0{1}\d{3}$/)
                ? ""
                : e.phone_number,
            editConfig: {
              ariaLabel: "Phone number",
              editIconAriaLabel: "editable",
              errorIconAriaLabel: "Phone number Error",
              validation(item, value) {
                if (errorMap.has(item)) {
                  if (value) {
                    errorMap.set(
                      item,
                      value.match(/^\d{3}-\d{3}-\d{4}$/)
                        ? undefined
                        : INVALID_INPUT
                    );
                  }
                  return errorMap.get(item);
                }
              },
              editingCell: (item, { currentValue, setValue }) => {
                return (
                  <Input
                    autoFocus={true}
                    value={currentValue ?? item.phone_number}
                    onChange={(event) => setValue(event.detail.value)}
                  />
                );
              },
              disabledReason: (item) => {
                return (
                  <div>
                    Please{" "}
                    <Button
                      variant="inline-link"
                      href={`mailto:hansoftwaredev@gmail.com?subject=CPS%20Change%20Phone%20Number%20Request&body=Customer:%20${item.customer_name}%0D%0AUpdated%20Phone%20Number:%20`}
                    >
                      notify the IT team
                    </Button>{" "}
                    to change this item.
                  </div>
                );
              },
            },
          },
          {
            id: "secondary_phone",
            header: "Secondary #",
            width: 150,
            minWidth: 50,
            cell: (e) => e.secondary_phone,
            editConfig: {
              ariaLabel: "Secondary #",
              editIconAriaLabel: "editable",
              errorIconAriaLabel: "Secondary # Error",
              editingCell: (item, { currentValue, setValue }) => {
                return (
                  <Input
                    autoFocus={true}
                    value={currentValue ?? item.secondary_phone}
                    onChange={(event) => setValue(event.detail.value)}
                  />
                );
              },
              disabledReason: (item) => {
                if (item.isChild) {
                  return "You cannot change this value. Change on the parent row instead.";
                }
                return undefined;
              },
            },
          },
          {
            id: "phone_info",
            header: "Phone info",
            width: 150,
            minWidth: 50,
            cell: (e) => e.phone_info,
            editConfig: {
              ariaLabel: "Phone info",
              editIconAriaLabel: "editable",
              errorIconAriaLabel: "Phone info Error",
              editingCell: (item, { currentValue, setValue }) => {
                return (
                  <Input
                    autoFocus={true}
                    value={currentValue ?? item.phone_info}
                    onChange={(event) => setValue(event.detail.value)}
                  />
                );
              },
              disabledReason: (item) => {
                if (item.isChild) {
                  return "You cannot change this value. Change on the parent row instead.";
                }
                return undefined;
              },
            },
          },
          {
            id: "email",
            header: "Email",
            width: 200,
            minWidth: 50,
            cell: (e) => e.email,
            editConfig: {
              ariaLabel: "Email",
              editIconAriaLabel: "editable",
              errorIconAriaLabel: "Email Error",
              editingCell: (item, { currentValue, setValue }) => {
                return (
                  <Input
                    autoFocus={true}
                    value={currentValue ?? item.email}
                    onChange={(event) => setValue(event.detail.value)}
                  />
                );
              },
              disabledReason: (item) => {
                if (item.isChild) {
                  return "You cannot change this value. Change on the parent row instead.";
                }
                return undefined;
              },
            },
          },
          {
            id: "gate",
            header: "Gate",
            width: 150,
            minWidth: 50,
            cell: (e) => e.gate,
            editConfig: {
              ariaLabel: "Gate",
              editIconAriaLabel: "editable",
              errorIconAriaLabel: "Gate Error",
              validation(item, value) {
                if (errorMap.has(item)) {
                  if (value) {
                    errorMap.set(
                      item,
                      value.indexOf(",") > -1 ? INVALID_INPUT : undefined
                    );
                  }
                  return errorMap.get(item);
                }
              },
              editingCell: (item, { currentValue, setValue }) => {
                return (
                  <Input
                    autoFocus={true}
                    value={currentValue ?? item.gate}
                    onChange={(event) => setValue(event.detail.value)}
                  />
                );
              },
            },
          },
          {
            id: "lsd",
            header: "Last service date",
            width: 150,
            minWidth: 100,
            cell: (e) => e.lsd,
            editConfig: {
              ariaLabel: "Last service date",
              editIconAriaLabel: "editable",
              errorIconAriaLabel: "Last service date Error",
              editingCell: (item, { currentValue, setValue }) => {
                return (
                  <Input
                    autoFocus={true}
                    value={currentValue ?? item.lsd}
                    onChange={(event) => setValue(event.detail.value)}
                  />
                );
              },
            },
          },
          {
            id: "plumber",
            header: "Plumber",
            width: 150,
            minWidth: 50,
            cell: (e) => e.plumber,
            editConfig: {
              ariaLabel: "Plumber",
              editIconAriaLabel: "editable",
              errorIconAriaLabel: "Plumber Error",
              editingCell: (item, { currentValue, setValue }) => {
                return (
                  <Input
                    autoFocus={true}
                    value={currentValue ?? item.plumber}
                    onChange={(event) => setValue(event.detail.value)}
                  />
                );
              },
            },
          },
          {
            id: "notes",
            header: "Notes",
            width: 800,
            minWidth: 200,
            cell: (e) => e.notes,
            editConfig: {
              ariaLabel: "Notes",
              editIconAriaLabel: "editable",
              errorIconAriaLabel: "Notes Error",
              editingCell: (item, { currentValue, setValue }) => {
                return (
                  <Input
                    autoFocus={true}
                    value={currentValue ?? item.notes}
                    onChange={(event) => setValue(event.detail.value)}
                  />
                );
              },
            },
          },
          {
            id: "add_address",
            header: "Add address",
            width: 130,
            minWidth: 130,
            cell: (e) =>
              e.isChild ? (
                ""
              ) : (
                <Button
                  variant="inline-link"
                  onClick={() => {
                    setAddAddressData(e);
                    setAddAddressVisible(true);
                  }}
                >
                  Add address
                </Button>
              ),
          },
        ]}
        header={
          <Container
            header={
              <Header
                variant="h1"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      onClick={() => {
                        if (editedItems.length > 0) {
                          // open "confirm changes" modal
                          setVisible(true);
                        } else {
                          setEditTable(false);
                        }
                      }}
                    >
                      Stop editing table <Spinner />
                    </Button>
                    <Button
                      variant="primary"
                      disabled
                      onClick={() => navigate("/")}
                    >
                      Job Ticket Generator <Icon name="arrow-right" />
                    </Button>
                  </SpaceBetween>
                }
              >
                Customer List (Editing)
              </Header>
            }
          >
            You are currently editing the table. Make sure to click the "Stop
            editing table" button to confirm or cancel your changes, then return
            to the regular customer list.
          </Container>
        }
      />
    </>
  );
}

export default CustomerList;
