import React, { useState, useEffect } from "react";
import axios from "axios";
import ToolsContent from "../Components/ToolsContent";
import { useLocation, useNavigate } from "react-router-dom";
import { Beforeunload } from "react-beforeunload";
import logo from "../Assets/castlelogo.png";

import {
  AppLayout,
  ContentLayout,
  Header,
  SpaceBetween,
  Container,
  Grid,
  Form,
  FormField,
  Button,
  Input,
  Multiselect,
  RadioGroup,
  Flashbar,
  Icon,
  Box,
  Textarea,
  Modal,
  Table,
} from "@cloudscape-design/components";

function JobTickets() {
  const navigate = useNavigate();
  const [toolsOpen, setToolsOpen] = useState(true);
  const [responseSuccessful, setResponseSuccessful] = useState("");
  const [flashbarSuccess, setFlashbarSuccess] = useState([]);
  const [flashbarFail, setFlashbarFail] = useState([]);
  const [ticketLoading, setTicketLoading] = useState(false);
  const [numTicketsQueue, setNumTicketsQueue] = useState(0);
  const [currentQueueData, setCurrentQueueData] = useState([]);

  const [settingsVisible, setSettingsVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [ticketSaveLoc, setTicketSaveLoc] = useState("");
  const [prevSaveLoc, setPrevSaveLoc] = useState("");
  const [invalidData, setInvalidData] = useState(false);

  // Validation
  const [errEta, setErrEta] = useState();
  const [errPlumbers, setErrPlumbers] = useState();
  const [errPaymentType, setErrPaymentType] = useState();
  const [errZip, setErrZip] = useState();
  const [errPhone, setErrPhone] = useState();
  const [errEmail, setErrEmail] = useState();
  const [gateCodeWarning, setGateCodeWarning] = useState();

  // Dates and time
  const [callDate, setCallDate] = useState("");
  const [jobDate, setJobDate] = useState("");
  const [eta, setEta] = useState("");

  // Customer info
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [unitNum, setUnitNum] = useState("");
  const [community, setCommunity] = useState("");
  const [gateCode, setGateCode] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [email, setEmail] = useState("");

  // Plumber info
  const [jobType, setJobType] = useState("Service Call");
  const [customJobType, setCustomJobType] = useState("");
  const [customJobLabel, setCustomJobLabel] = useState("Custom (type below)");
  const [plumberSelected, setPlumberSelected] = useState(false);
  const [selectedPlumbers, setSelectedPlumbers] = useState([]);
  const [estJobTime, setEstJobTime] = useState("");
  const [paymentType, setPaymentType] = useState("");

  const [jobNotes1, setJobNotes1] = useState("");
  const [jobNotes2, setJobNotes2] = useState("");
  const [jobNotes3, setJobNotes3] = useState("");
  const [jobNotes4, setJobNotes4] = useState("");
  const [jobNotes5, setJobNotes5] = useState("");
  const [jobNotes6, setJobNotes6] = useState("");
  const [comments1, setComments1] = useState("");
  const [comments2, setComments2] = useState("");
  const [autofillNotes, setAutofillNotes] = useState("");

  useEffect(() => {
    completeAutofill();
  }, []);

  // validate data with errors
  const validateEta = (eta) => {
    if (!eta) {
      setErrEta("Required for job type");
    } else if (eta.length > 0 && !eta.match(/^[0-9\s:-]+$/)) {
      setErrEta("Please use only numbers, dashes and colons");
    } else {
      setErrEta(false);
    }
  };
  const validatePlumbers = (plumbers) => {
    if (plumberSelected && plumbers < 1) {
      setErrPlumbers("You must choose at least one plumber");
    } else if (!plumberSelected && plumbers < 1) {
      setErrPlumbers("You must choose at least one plumber");
    } else {
      setErrPlumbers(false);
    }
  };
  const validatePaymentType = (paymentType) => {
    if (!paymentType) {
      setErrPaymentType("You must choose one option");
    } else {
      setErrPaymentType(false);
    }
  };
  const validateZip = (zip) => {
    if (zip.length > 0 && zip.length !== 5) {
      setErrZip("Too many characters. Please confirm correct zip code.");
    } else if (zip.length > 0 && !zip.match(/^\d+$/)) {
      setErrZip("Please confirm zip code matches example format.");
    } else if (!zip) {
      setErrZip("Required for job type");
    } else {
      setErrZip(false);
    }
  };
  const validatePhone = (phoneNum) => {
    if (1 > phoneNum.length > 12) {
      setErrPhone("Too many characters. Please confirm correct phone number");
    } else if (
      phoneNum.length > 0 &&
      !phoneNum.match(/^[0-9]{3}[-]?[0-9]{3}[-]?[0-9]{4}$/)
    ) {
      setErrPhone("Confirm phone number matches example format");
    } else {
      setErrPhone(false);
    }
  };
  const validateEmail = (email) => {
    if (
      email.length > 0 &&
      !email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setErrEmail("Please make sure email address is correct.");
    } else {
      setErrEmail(false);
    }
  };

  const clearInputs = () => {
    setCallDate("");
    setJobDate("");
    setEta("");
    setName("");
    setAddress("");
    setZip("");
    setUnitNum("");
    setCommunity("");
    setGateCode("");
    setPhoneNum("");
    setEmail("");
    setJobType([]);
    setPlumberSelected(false);
    setSelectedPlumbers([]);
    setEstJobTime("");
    setPaymentType("");
    setJobNotes1("");
    setJobNotes2("");
    setJobNotes3("");
    setJobNotes4("");
    setJobNotes5("");
    setJobNotes6("");
    setComments1("");
    setComments2("");
    setAutofillNotes("");
  };

  const addTicketQueue = (event) => {
    // do validation/error checking
    let validInput = checkInputs();
    if (validInput === true) {
      setTicketLoading(true);
      event.preventDefault();
      axios
        .post("/addTicketQueue", {
          jobType: jobType,
          plumbers: selectedPlumbers,
          gateCode: gateCode,
          callDate: callDate,
          estJobTime: estJobTime,
          jobDate: jobDate,
          eta: eta,
          name: name,
          community: community,
          zip: zip,
          address: address,
          unitNum: unitNum,
          jobNotes: {
            notes1: jobNotes1,
            notes2: jobNotes2,
            notes3: jobNotes3,
            notes4: jobNotes4,
            notes5: jobNotes5,
            notes6: jobNotes6,
          },
          phoneNum: phoneNum,
          paymentType: paymentType,
          email: email,
          comments: {
            line1: comments1,
            line2: comments2,
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.status === 200) {
            setFlashbarSuccess([
              {
                header: "Ticket added to queue successfully",
                type: "success",
                content: response.data.message,
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: () => {
                  setFlashbarSuccess([]);
                  setResponseSuccessful("");
                },
              },
            ]);
            setNumTicketsQueue(response.data.numTickets);
            setResponseSuccessful(true);
            setTicketLoading(false);
            clearInputs();
          } else {
            setFlashbarFail([
              {
                header: "Failed to add ticket to queue",
                type: "error",
                content:
                  response.data.error +
                  " Click the button to the right to send a report to the IT Staff.",
                action: (
                  <Button href="mailto:hansoftwaredev@gmail.com?subject=CPS%20Error%20Report">
                    Send report
                  </Button>
                ),
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: () => {
                  setFlashbarFail([]);
                  setResponseSuccessful("");
                },
              },
            ]);
            setTicketLoading(false);
            setResponseSuccessful(false);
          }
        })
        .catch((error) => {
          setTicketLoading(false);
          setResponseSuccessful(false);
          console.error(error);
        });
    } else {
      setResponseSuccessful(false);
      if (validInput.length > 1) {
        setFlashbarFail([
          {
            header: "Ticket was not added to queue",
            type: "error",
            content:
              "The following inputs show errors: " +
              validInput.join(", ") +
              ". Please review any inputs with errors, then try adding the ticket again.",
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => {
              setFlashbarFail([]);
              setResponseSuccessful("");
            },
          },
        ]);
      } else {
        setFlashbarFail([
          {
            header: "Ticket was not added to queue",
            type: "error",
            content:
              "The following input has an error: " +
              validInput.toString() +
              ". Please review the error, then try adding the ticket again.",
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => {
              setFlashbarFail([]);
              setResponseSuccessful("");
            },
          },
        ]);
      }
    }
  };

  const completeTicketQueue = (event) => {
    // do validation/error checking
    setTicketLoading(true);
    event.preventDefault();
    axios
      .post("/completeTicketQueue")
      .then((response) => {
        if (response.data.status === 200) {
          if (response.data.fileName.length > 1) {
            let regex = /\\[^\\]+$/; // match last backslash then remove until end of string
            let path = response.data.fileLoc.replace(regex, "");
            setFlashbarSuccess([
              {
                header: "Tickets created successfully",
                type: "success",
                content: "Tickets are located at " + path + ".",
                action: (
                  <Button
                    onClick={() => {
                      axios
                        .post("/openTicket", { path })
                        .then((response) => {
                          //console.log(response.data);
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    }}
                  >
                    View tickets in folder
                  </Button>
                ),
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: () => {
                  setFlashbarSuccess([]);
                  setResponseSuccessful("");
                },
              },
            ]);
          } else {
            let path = response.data.fileLoc;
            setFlashbarSuccess([
              {
                header: "Ticket created successfully",
                type: "success",
                content: "Ticket is located at " + path,
                action: (
                  <Button
                    onClick={() => {
                      axios
                        .post("/openTicket", { path })
                        .then((response) => {
                          //console.log(response.data);
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    }}
                  >
                    View ticket
                  </Button>
                ),
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: () => {
                  setFlashbarSuccess([]);
                  setResponseSuccessful("");
                },
              },
            ]);
          }
          setResponseSuccessful(true);
          setTicketLoading(false);
          clearInputs();
          setNumTicketsQueue(0);
        } else {
          setFlashbarFail([
            {
              header: "Failed to create ticket(s)",
              type: "error",
              content:
                response.data.error +
                " Click the button to the right to send a report to the IT Staff.",
              action: (
                <Button
                  href={
                    "mailto:hansoftwaredev@gmail.com?subject=CPS%20Error%20Report&body=" +
                    response.data.error
                  }
                >
                  Send report
                </Button>
              ),
              dismissible: true,
              dismissLabel: "Dismiss message",
              onDismiss: () => {
                setFlashbarFail([]);
                setResponseSuccessful("");
              },
            },
          ]);
          setTicketLoading(false);
          setResponseSuccessful(false);
        }
        setNumTicketsQueue(0);
      })
      .catch((error) => {
        setTicketLoading(false);
        setResponseSuccessful(false);
        console.error(error);
      });
  };

  const reloadQueue = (event) => {
    setTicketLoading(true);
    event.preventDefault();
    axios
      .get("/reloadQueue")
      .then((response) => {
        if (response.data.status === 200) {
          setNumTicketsQueue(response.data.numTickets);
          setFlashbarSuccess([
            {
              header: "Ticket queue reloaded",
              type: "success",
              content:
                "You may now complete the queue or add to it. Number of tickets in queue: " +
                response.data.numTickets,
              dismissible: true,
              dismissLabel: "Dismiss message",
              onDismiss: () => {
                setFlashbarSuccess([]);
                setResponseSuccessful("");
              },
            },
          ]);
          setResponseSuccessful(true);
          setTicketLoading(false);
          clearInputs();
        } else {
          setFlashbarFail([
            {
              header: "Failed to create ticket(s)",
              type: "error",
              content:
                response.data.error +
                " Click the button to the right to send a report to the IT Staff.",
              action: (
                <Button
                  href={
                    "mailto:hansoftwaredev@gmail.com?subject=CPS%20Error%20Report&body=" +
                    response.data.error
                  }
                >
                  Send report
                </Button>
              ),
              dismissible: true,
              dismissLabel: "Dismiss message",
              onDismiss: () => {
                setFlashbarFail([]);
                setResponseSuccessful("");
              },
            },
          ]);
          setTicketLoading(false);
          setResponseSuccessful(false);
        }
      })
      .catch((error) => {
        setTicketLoading(false);
        setResponseSuccessful(false);
        console.error(error);
      });
  };

  const viewCurrentQueue = (event) => {
    event.preventDefault();
    axios
      .get("/viewCurrentQueue")
      .then((response) => {
        if (response.data.status === 200) {
          var callDates = response.data.callDates;
          var jobDates = response.data.jobDates;
          var names = response.data.names;
          var phoneNums = response.data.phoneNums;
          for (let i = 0; i < names.length; i++) {
            var obj = {
              name: names[i],
              callDate: callDates[i],
              jobDate: jobDates[i],
              phoneNum: phoneNums[i],
            };
            currentQueueData.push(obj);
          }
          setVisible(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getSaveLoc = () => {
    axios
      .get("/getSaveLoc")
      .then((response) => {
        if (response.data.status === 200) {
          setTicketSaveLoc(response.data.saveLoc);
          setPrevSaveLoc(response.data.saveLoc);
        } else {
          console.error("Could not retrieve ticket save location");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const putSaveLoc = (saveLoc) => {
    axios
      .post("/putSaveLoc", { saveLoc })
      .then((response) => {
        if (response.data.status === 200) {
          console.log("success");
        } else {
          console.error("Could not save ticket save location");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const checkInputs = () => {
    var errors = [];
    if (jobType === "Service Call") {
      validateEta(eta);
      validatePlumbers(selectedPlumbers);
      validatePaymentType(paymentType);
      validateZip(zip);
      validatePhone(phoneNum);
      validateEmail(email);
      if (!callDate || !jobDate || !name || !address) {
        errors.push("Date, name, and/or address is blank");
      }
      if (errEta !== false) errors.push("Time frame (ETA)");
      if (errPlumbers !== false) errors.push("Plumber(s) assigned");
      if (errPaymentType !== false) errors.push("Payment type");
      if (errZip !== false) errors.push("Zip code");
      if (errPhone !== false) errors.push("Phone number");
      if (errEmail !== false) errors.push("Email address");
    }
    if (jobType === "Job View") {
      validateEta(eta);
      validatePlumbers(selectedPlumbers);
      validateZip(zip);
      validatePhone(phoneNum);
      validateEmail(email);
      if (!callDate || !jobDate || !name || !address) {
        errors.push("Date, name, and/or address is blank");
      }
      if (errEta !== false) errors.push("Time frame (ETA)");
      if (errPlumbers !== false) errors.push("Plumber(s) assigned");
      if (errZip !== false) errors.push("Zip code");
      if (errPhone !== false) errors.push("Phone number");
      if (errEmail !== false) errors.push("Email address");
    }
    if (jobType === "Product") {
      validatePlumbers(selectedPlumbers);
      if (!callDate || !jobDate || !name) {
        errors.push("Date, name, and/or address is blank");
      }
      if (errPlumbers !== false) errors.push("Plumber(s) assigned");
    }
    if (jobType === customJobType && jobType !== "") {
      if (zip.length > 0) validateZip(zip);
      if (phoneNum.length > 0) validatePhone(phoneNum);
      if (email.length > 0) validateEmail(email);
      if (errZip !== false) errors.push("Zip code");
      if (errPhone !== false) errors.push("Phone number");
      if (errEmail !== false) errors.push("Email address");
    }
    if (errors.length > 0) {
      return errors;
    } else {
      return true;
    }
  };

  const { state } = useLocation();
  const completeAutofill = () => {
    if (state) {
      let data = state.e;
      //console.log(data);

      // today's date function
      var today = new Date();
      var dd = String(today.getDate());
      var mm = String(today.getMonth() + 1);
      var yy = today.getFullYear().toString().slice(-2);
      today = mm + "/" + dd + "/" + yy;

      let splitNames = data.customer_name.replaceAll(" ", "").split(",");
      let formattedName = `${splitNames[1]} ${splitNames[0]}`;
      if (formattedName.includes("(NateHerman)")) {
        formattedName = formattedName.replace("(NateHerman)", "");
      }
      if (data.isChild) {
        formattedName = formattedName.replace(/-\d/, ""); // remove "-1", "-2", etc due to child address
      }

      let plumbers = data.plumber;
      let newSelectedPlumbers = [];
      let newPlumberSelected = false;
      if (plumbers.length >= 1) {
        // split by commas, remove whitespace
        let plumberArr = plumbers.split(",");
        let trimmed = plumberArr.map((plumber) => plumber.trim());
        let newArr = [];
        trimmed.forEach((plumber) => {
          let bruh = {};
          if (plumber === "Bill") {
            bruh = {
              label: "Bill Hanson",
              value: "B",
            };
          } else if (
            plumber === "ES" ||
            plumber === "Ed" ||
            plumber === "Eddy"
          ) {
            bruh = {
              label: "Ed Skillen",
              value: "ES",
            };
          } else if (plumber === "Jake") {
            bruh = {
              label: "Jake Hanson",
              value: "J",
            };
          }
          newArr.push(bruh);
        });
        newSelectedPlumbers = newArr;
        newPlumberSelected = true;
      } else {
        newSelectedPlumbers = [];
        newPlumberSelected = false;
      }

      setSelectedPlumbers(newSelectedPlumbers);
      setPlumberSelected(newPlumberSelected);

      setCallDate(today);
      setName(formattedName);
      setAddress(data.service_address);
      setZip(data.zip);
      setUnitNum(data.unit);
      setCommunity(data.community);
      setGateCode(data.gate);
      setPhoneNum(data.phone_number);
      setEmail(data.email);
      setAutofillNotes(data.notes);
    }
  };

  function limitWidth(inputField) {
    const maxWidth1stLine = 235;
    const maxWidth = 275;
    const text = inputField;
    const span = document.createElement("span");
    span.style.visibility = "hidden";
    span.style.whiteSpace = "nowrap";
    span.innerText = text;
    document.body.appendChild(span);
    const width = span.getBoundingClientRect().width;
    document.body.removeChild(span);

    if (
      (inputField === jobNotes1 && width > maxWidth1stLine) ||
      (inputField === comments1 && width > maxWidth1stLine)
    ) {
      return "Text size will be reduced due to character count";
    } else if (width > maxWidth) {
      return "Text size will be reduced due to character count";
    } else {
      return "";
    }
  }

  const capitalizeCustomJobType = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1);
  };

  const closeServer = () => {
    axios.post("/closeServer").catch((error) => {
      console.error(error);
    });
  };

  return (
    <AppLayout
      headerVariant="high-contrast"
      headerSelector="#top-nav"
      ariaLabels={{
        navigation: "Navigation drawer",
        navigationClose: "Close navigation drawer",
        navigationToggle: "Open navigation drawer",
        notifications: "Notifications",
        tools: "FAQ Panel",
        toolsClose: "Close FAQ Panel",
        toolsToggle: "Open FAQ Panel",
      }}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      navigationHide
      content={
        <div>
          <Beforeunload onBeforeunload={closeServer} />
          <Modal
            onDismiss={() => {
              //setTicketSaveLoc(prevSaveLoc);
              setSettingsVisible(false);
            }}
            visible={settingsVisible}
            size="large"
            footer={
              <>
                <Box float="left">
                  <Button
                    onClick={() => {
                      //setTicketSaveLoc(prevSaveLoc);
                      setSettingsVisible(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>

                <Box float="right">
                  <Button
                    variant="primary"
                    onClick={() => {
                      putSaveLoc(ticketSaveLoc);
                      setSettingsVisible(false);
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </>
            }
            header="Settings"
          >
            <FormField
              description="Where you want your tickets to save by default."
              label="Ticket Save Location"
            >
              <Input
                value={ticketSaveLoc}
                onChange={({ detail }) => setTicketSaveLoc(detail.value)}
              />
            </FormField>
          </Modal>
          <Modal
            onDismiss={() => {
              setVisible(false);
              setCurrentQueueData([]);
            }}
            visible={visible}
            size="max"
            footer={
              <Box float="right">
                <Button
                  variant="primary"
                  onClick={() => {
                    setVisible(false);
                    setCurrentQueueData([]);
                  }}
                >
                  Return to Job Ticket Generator
                </Button>
              </Box>
            }
            header="Current queue"
          >
            <SpaceBetween direction="vertical" size="s">
              <Table
                items={currentQueueData}
                loadingText="Loading queue"
                stripedRows
                stickyHeader
                resizableColumns={true}
                trackBy="name"
                variant="borderless"
                columnDefinitions={[
                  {
                    id: "name",
                    header: "Customer name",
                    width: 250,
                    minWidth: 250,
                    sortingField: "name",
                    isRowHeader: true,
                    cell: (e) => e.name,
                  },
                  {
                    id: "callDate",
                    header: "Call date",
                    width: 120,
                    minWidth: 120,
                    cell: (e) => e.callDate,
                  },
                  {
                    id: "jobDate",
                    header: "Job date",
                    width: 120,
                    minWidth: 120,
                    cell: (e) => e.jobDate,
                  },
                  {
                    id: "phoneNum",
                    header: "Phone number",
                    width: 150,
                    minWidth: 150,
                    cell: (e) => e.phoneNum,
                  },
                ]}
              />
            </SpaceBetween>
          </Modal>
          <ContentLayout
            headerVariant="high-contrast"
            header={
              <Header
                variant="h1"
                description="View the User Guide for any issues encountered."
                actions={
                  <SpaceBetween direction="horizontal" size="s">
                    <Button
                      onClick={() => {
                        localStorage.setItem("accessToken", "bruh");
                      }}
                    >
                      TESTING BUTTON
                    </Button>
                    <Button
                      onClick={() => {
                        getSaveLoc();
                        setSettingsVisible(true);
                      }}
                    >
                      <Icon name="settings" />
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => navigate("/customer-list")}
                    >
                      Customer List &nbsp;
                      <Icon name="caret-right-filled" />
                    </Button>
                  </SpaceBetween>
                }
              >
                <h2>
                  Job Ticket Generator{" "}
                  <img
                    src={logo}
                    style={{
                      position: "absolute",
                      top: -60,
                      height: 240,
                      opacity: 0.5,
                    }}
                  />
                </h2>
              </Header>
            }
          >
            <form onSubmit={(e) => e.preventDefault()}>
              <Form
              // errorText={} use regex function for all bad values
              // if one value is bad, throw error and disable both form buttons
              >
                <SpaceBetween direction="vertical" size="s">
                  {/* ----- Start Form Containers ----- */}
                  <Grid
                    gridDefinition={[
                      { colspan: 6 },
                      { colspan: 6 },
                      { colspan: 6 },
                      { colspan: 6 },
                    ]}
                  >
                    <Container
                      fitHeight
                      header={<Header variant="h3">Date and Time</Header>}
                    >
                      <SpaceBetween direction="vertical" size="m">
                        <FormField
                          label="Call date"
                          constraintText="Example: 03/08/2024 or 4/3/24"
                          stretch
                        >
                          <Input
                            onChange={({ detail }) => setCallDate(detail.value)}
                            value={callDate}
                          />
                        </FormField>
                        <FormField
                          label="Scheduled job date"
                          constraintText="Example:  Friday 03/08/2024 or Wed 4/3/24"
                          stretch
                        >
                          <Input
                            onChange={({ detail }) => setJobDate(detail.value)}
                            value={jobDate}
                          />
                        </FormField>
                        <FormField
                          label="Time frame (ETA)"
                          constraintText="Example: 9-10, 12-4, etc."
                          stretch
                          errorText={errEta}
                        >
                          <Input
                            onChange={({ detail }) => {
                              setEta(detail.value);
                              validateEta(detail.value);
                            }}
                            value={eta}
                            disabled={jobType === "Product"}
                          />
                        </FormField>
                      </SpaceBetween>
                    </Container>
                    <Container
                      fitHeight
                      header={<Header variant="h3">Plumber Information</Header>}
                    >
                      <SpaceBetween direction="vertical" size="m">
                        <FormField label="Job type" stretch>
                          <RadioGroup
                            onChange={({ detail }) => setJobType(detail.value)}
                            value={jobType}
                            items={[
                              {
                                value: "Service Call",
                                label: "Service Call",
                              },
                              { value: "Job View", label: "Job View" },
                              { value: "Product", label: "Product" },
                              {
                                value: customJobType,
                                label: customJobLabel,
                              },
                            ]}
                          />
                        </FormField>
                        {jobType !== "Service Call" &&
                        jobType !== "Job View" &&
                        jobType !== "Product" ? (
                          <SpaceBetween direction="horizontal" size="xxs">
                            <FormField
                              constraintText="You MUST click 'Ok' for the job type to take effect"
                              errorText={
                                customJobType.length > 12
                                  ? "Too many characters. Keep it simple!"
                                  : ""
                              }
                            >
                              <Input
                                onChange={({ detail }) => {
                                  setCustomJobType(detail.value);
                                }}
                                placeholder="Custom job type"
                                value={customJobType}
                              />
                            </FormField>
                            <Button
                              onClick={() => {
                                setCustomJobType(
                                  capitalizeCustomJobType(customJobType)
                                );
                                setJobType(
                                  capitalizeCustomJobType(customJobType)
                                );
                                setCustomJobLabel(
                                  capitalizeCustomJobType(customJobType)
                                );
                              }}
                              disabled={customJobType.length > 12}
                            >
                              Ok
                            </Button>
                          </SpaceBetween>
                        ) : (
                          ""
                        )}
                        <FormField
                          label="Plumber(s) assigned"
                          stretch
                          errorText={errPlumbers}
                        >
                          <Multiselect
                            selectedOptions={selectedPlumbers}
                            onChange={({ detail }) => {
                              setPlumberSelected(true);
                              setSelectedPlumbers(detail.selectedOptions);
                              validatePlumbers(detail.selectedOptions);
                            }}
                            options={[
                              {
                                label: "Bill Hanson",
                                value: "B",
                              },
                              {
                                label: "Ed Skillen",
                                value: "ES",
                              },
                              {
                                label: "Jake Hanson",
                                value: "J",
                              },
                            ]}
                            placeholder="Choose plumber(s)"
                          />
                        </FormField>
                        <FormField
                          label="Estimated job completion time"
                          constraintText="Example: 1-2 hrs"
                          stretch
                        >
                          <Input
                            onChange={({ detail }) =>
                              setEstJobTime(detail.value)
                            }
                            value={estJobTime}
                            disabled={
                              jobType === "Job View" || jobType === "Product"
                            }
                          />
                        </FormField>
                        <FormField
                          label="Payment type"
                          stretch
                          errorText={errPaymentType}
                        >
                          <RadioGroup
                            onChange={({ detail }) =>
                              setPaymentType(detail.value)
                            }
                            value={paymentType}
                            items={[
                              {
                                value: "ATS",
                                label: "At time of service",
                                disabled:
                                  jobType === "Job View" ||
                                  jobType === "Product",
                              },
                              {
                                value: "Office",
                                label: "Office",
                                disabled:
                                  jobType === "Job View" ||
                                  jobType === "Product",
                              },
                            ]}
                          />
                        </FormField>
                      </SpaceBetween>
                    </Container>
                    <Container
                      fitHeight
                      header={
                        <Header variant="h3">Customer Information</Header>
                      }
                    >
                      <SpaceBetween direction="vertical" size="m">
                        <FormField label="Name" stretch>
                          <Input
                            onChange={({ detail }) => setName(detail.value)}
                            value={name}
                            inputMode="text"
                          />
                        </FormField>
                        <FormField label="Address" stretch>
                          <Input
                            onChange={({ detail }) => setAddress(detail.value)}
                            value={address}
                            disabled={jobType === "Product"}
                          />
                        </FormField>
                        <FormField label="Zip code" stretch errorText={errZip}>
                          <Input
                            onChange={({ detail }) => {
                              setZip(detail.value);
                              validateZip(detail.value);
                            }}
                            value={zip}
                            disabled={jobType === "Product"}
                          />
                        </FormField>
                        <FormField label="Unit #" stretch>
                          <Input
                            onChange={({ detail }) => setUnitNum(detail.value)}
                            value={unitNum}
                            disabled={jobType === "Product"}
                          />
                        </FormField>
                        <FormField
                          label="Community, building, etc."
                          constraintText="Island Walk, Dorchester, etc."
                          stretch
                        >
                          <Input
                            onChange={({ detail }) =>
                              setCommunity(detail.value)
                            }
                            value={community}
                            disabled={jobType === "Product"}
                          />
                        </FormField>
                        <FormField
                          label="Gate code"
                          stretch
                          warningText={
                            gateCodeWarning && !gateCode
                              ? "Confirm that address does not require gate code"
                              : ""
                          }
                        >
                          <Input
                            onChange={({ detail }) => setGateCode(detail.value)}
                            onFocus={setGateCodeWarning}
                            value={gateCode}
                            disabled={jobType === "Product"}
                          />
                        </FormField>
                        <FormField
                          label="Phone number"
                          constraintText="Example: 239-455-2295"
                          stretch
                          errorText={errPhone}
                        >
                          <Input
                            onChange={({ detail }) => {
                              setPhoneNum(detail.value);
                              validatePhone(detail.value);
                            }}
                            value={phoneNum}
                            inputMode="tel"
                            disabled={jobType === "Product"}
                          />
                        </FormField>
                        <FormField
                          label="Email address"
                          constraintText="Example: michaelknight@gmail.com"
                          stretch
                          errorText={errEmail}
                        >
                          <Input
                            onChange={({ detail }) => {
                              setEmail(detail.value);
                              validateEmail(detail.value);
                            }}
                            value={email}
                            inputMode="email"
                            disabled={jobType === "Product"}
                          />
                        </FormField>
                        {email.length > 0 ? (
                          <SpaceBetween direction="horizontal" size="s">
                            <Button
                              onClick={() => {
                                setEmail(email + "@gmail.com");
                                validateEmail(email + "@gmail.com");
                              }}
                            >
                              @gmail.com
                            </Button>
                            <Button
                              onClick={() => {
                                setEmail(email + "@yahoo.com");
                                validateEmail(email + "@yahoo.com");
                              }}
                            >
                              @yahoo.com
                            </Button>
                            <Button
                              onClick={() => {
                                setEmail(email + "@hotmail.com");
                                validateEmail(email + "@hotmail.com");
                              }}
                            >
                              @hotmail.com
                            </Button>
                            <Button
                              onClick={() => {
                                setEmail(email + "@comcast.net");
                                validateEmail(email + "@comcast.net");
                              }}
                            >
                              @comcast.net
                            </Button>
                            <Button
                              onClick={() => {
                                setEmail(email + "@outlook.com");
                                validateEmail(email + "@outlook.com");
                              }}
                            >
                              @outlook.com
                            </Button>
                            <Button
                              onClick={() => {
                                setEmail(email + "@icloud.com");
                                validateEmail(email + "@icloud.com");
                              }}
                            >
                              @icloud.com
                            </Button>
                            <Button
                              onClick={() => {
                                setEmail(email + "@aol.com");
                                validateEmail(email + "@aol.com");
                              }}
                            >
                              @aol.com
                            </Button>
                          </SpaceBetween>
                        ) : (
                          ""
                        )}
                      </SpaceBetween>
                    </Container>
                    <Container
                      fitHeight
                      header={<Header variant="h3">Notes</Header>}
                    >
                      <SpaceBetween direction="vertical" size="m">
                        <SpaceBetween direction="vertical" size="xxs">
                          <FormField
                            label="Job Notes"
                            stretch
                            warningText={limitWidth(jobNotes1)}
                          >
                            <Input
                              onChange={({ detail }) =>
                                setJobNotes1(detail.value)
                              }
                              value={jobNotes1}
                              placeholder="Job notes line 1"
                            />
                          </FormField>
                          <FormField
                            stretch
                            warningText={limitWidth(jobNotes2)}
                          >
                            <Input
                              onChange={({ detail }) =>
                                setJobNotes2(detail.value)
                              }
                              value={jobNotes2}
                              placeholder="Job notes line 2"
                            />
                          </FormField>
                          <FormField
                            stretch
                            warningText={limitWidth(jobNotes3)}
                          >
                            <Input
                              onChange={({ detail }) =>
                                setJobNotes3(detail.value)
                              }
                              value={jobNotes3}
                              placeholder="Job notes line 3"
                            />
                          </FormField>
                          <FormField
                            stretch
                            warningText={limitWidth(jobNotes4)}
                          >
                            <Input
                              onChange={({ detail }) =>
                                setJobNotes4(detail.value)
                              }
                              value={jobNotes4}
                              placeholder="Job notes line 4"
                            />
                          </FormField>
                          <FormField
                            stretch
                            warningText={limitWidth(jobNotes5)}
                          >
                            <Input
                              onChange={({ detail }) =>
                                setJobNotes5(detail.value)
                              }
                              value={jobNotes5}
                              placeholder="Job notes line 5"
                            />
                          </FormField>
                          <FormField
                            stretch
                            warningText={limitWidth(jobNotes6)}
                          >
                            <Input
                              onChange={({ detail }) =>
                                setJobNotes6(detail.value)
                              }
                              value={jobNotes6}
                              placeholder="Job notes line 6"
                            />
                          </FormField>
                        </SpaceBetween>
                        <SpaceBetween direction="vertical" size="xxs">
                          <FormField
                            label="Office Comments"
                            stretch
                            warningText={limitWidth(comments1)}
                          >
                            <Input
                              onChange={({ detail }) =>
                                setComments1(detail.value)
                              }
                              value={comments1}
                              placeholder="Office notes line 1"
                            />
                          </FormField>
                          <FormField
                            stretch
                            warningText={limitWidth(comments2)}
                          >
                            <Input
                              onChange={({ detail }) =>
                                setComments2(detail.value)
                              }
                              value={comments2}
                              placeholder="Office notes line 2"
                            />
                          </FormField>
                        </SpaceBetween>
                        {autofillNotes !== "" ? (
                          <FormField
                            label="Notes from Autofill (read only)"
                            stretch
                          >
                            <Textarea
                              onChange={({ detail }) =>
                                setAutofillNotes(detail.value)
                              }
                              value={autofillNotes}
                              readOnly
                            />
                          </FormField>
                        ) : (
                          ""
                        )}
                      </SpaceBetween>
                    </Container>
                  </Grid>
                  {ticketLoading ? (
                    <Flashbar
                      items={[
                        {
                          type: "info",
                          loading: true,
                          content: "Ticket is being created, please wait.",
                          dismissible: false,
                        },
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  {responseSuccessful === true ? (
                    <Flashbar items={flashbarSuccess} />
                  ) : (
                    ""
                  )}
                  {responseSuccessful === false ? (
                    <Flashbar items={flashbarFail} />
                  ) : (
                    ""
                  )}
                </SpaceBetween>
              </Form>
            </form>
            <Box float="left" padding="s">
              <SpaceBetween direction="horizontal" size="s">
                <Button onClick={reloadQueue} variant="link">
                  Reload previous queue
                </Button>
                <Button
                  onClick={viewCurrentQueue}
                  variant="link"
                  disabled={numTicketsQueue < 1}
                >
                  View current queue
                </Button>
              </SpaceBetween>
            </Box>
            <Box float="right" padding="s">
              <SpaceBetween direction="horizontal" size="s">
                <Button onClick={addTicketQueue}>Add ticket to queue</Button>
                <Button
                  onClick={completeTicketQueue}
                  variant="primary"
                  disabled={numTicketsQueue < 1}
                >
                  Complete ticket queue
                </Button>
              </SpaceBetween>
            </Box>
          </ContentLayout>
        </div>
      }
      tools={ToolsContent.faq}
    />
  );
}

export default JobTickets;
