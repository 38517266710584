import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../Assets/castlelogo.png";

import {
  GetUserCommand,
  ConfirmSignUpCommand,
  SignUpCommand,
  InitiateAuthCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { cognitoClient, userPoolID, clientID } from "../Components/aws-config";

import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
  MailOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Typography,
  Layout,
  Menu,
  theme,
  Image,
  Flex,
} from "antd";

const { Title } = Typography;

function Login() {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { Header, Content, Footer, Sider } = Layout;
  const items1 = ["1", "2", "3"].map((key) => ({
    key,
    label: `nav ${key}`,
  }));
  const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map(
    (icon, index) => {
      const key = String(index + 1);
      return {
        key: `sub${key}`,
        icon: React.createElement(icon),
        label: `subnav ${key}`,
        children: new Array(4).fill(null).map((_, j) => {
          const subKey = index * 4 + j + 1;
          return {
            key: subKey,
            label: `option${subKey}`,
          };
        }),
      };
    }
  );

  const navigate = useNavigate();
  document.title = "Sign in | Hansoftware";
  const [signUpVisible, setSignUpVisible] = useState(false);
  const [signUpEmail, setSignUpEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [signUpPassword, setSignUpPassword] = useState("");

  const [verifyVisible, setVerifyVisible] = useState(false);
  const [code, setCode] = useState("");

  const handleSignUp = async () => {
    try {
      console.log("name: ", name);
      console.log("phone: ", phoneNumber);
      console.log("address: ", address);
      const command = new SignUpCommand({
        ClientId: clientID,
        Username: signUpEmail,
        Password: signUpPassword,
        UserAttributes: [
          {
            Name: "name",
            Value: name,
          },
          {
            Name: "phone_number",
            Value: phoneNumber,
          },
          {
            Name: "address",
            Value: address,
          },
        ],
      });

      const response = await cognitoClient.send(command);
      console.log("Sign up successful:", response);
      setSignUpVisible(false);
      setVerifyVisible(true);
    } catch (error) {
      console.error("Error signing up:", error);
    }
  };

  const handleVerifyEmail = async () => {
    try {
      console.log("name: ", name);
      console.log("phone: ", phoneNumber);
      console.log("address: ", address);
      const command = new ConfirmSignUpCommand({
        ClientId: clientID,
        Username: signUpEmail,
        ConfirmationCode: code,
      });

      const response = await cognitoClient.send(command);
      console.log("Code verification successful:", response);
      setVerifyVisible(false);
    } catch (error) {
      console.error("Error with code:", error);
      // show error, allow user to try code again
    }
  };

  const onFinish = (values) => {
    handleLogin(values.email, values.password)
      .then((response) => {
        navigate("/dashboard");
      })
      .catch((error) => {
        // show error
        console.log(error);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    // show error
  };

  const handleLogin = async (email, password) => {
    try {
      const command = new InitiateAuthCommand({
        AuthFlow: "USER_PASSWORD_AUTH",
        ClientId: clientID,
        AuthParameters: {
          USERNAME: email,
          PASSWORD: password,
        },
      });

      const response = await cognitoClient.send(command);
      const { IdToken, AccessToken, RefreshToken, ExpiresIn } =
        response.AuthenticationResult;
      const expirationTime = Date.now() + ExpiresIn * 1000; // ExpiresIn is in seconds, date is in milliseconds
      // may change this to localForage in the future for JSON support
      localStorage.setItem("accessToken", AccessToken);
      localStorage.setItem("idToken", IdToken);
      localStorage.setItem("refreshToken", RefreshToken);
      localStorage.setItem("tokenExpiration", expirationTime);
      // get name, email, etc.
      getUserAttributes(AccessToken);
      return "SUCCESS";
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  const getUserAttributes = async (accessToken) => {
    try {
      const command = new GetUserCommand({
        AccessToken: accessToken,
      });
      const response = await cognitoClient.send(command);
      console.log("User logged in");
      localStorage.setItem("email", response.UserAttributes[0].Value);
      localStorage.setItem("name", response.UserAttributes[4].Value);
    } catch (error) {
      console.log("User not logged in");
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <Layout>
        <Content
          style={{
            padding: "40px 40px 0px 40px",
          }}
        >
          <Flex style={{ width: "100%" }} justify="center" align="center">
            <Layout
              style={{
                padding: "0px 20px",
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                maxWidth: "100vw",
              }}
            >
              <Content
                style={{
                  padding: "40px 5px",
                  minHeight: 300,
                  paddingLeft: 50,
                }}
              >
                <Title>Sign in</Title>
                <Form
                  name="basic"
                  labelAlign="left"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    icon={<MailOutlined />}
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    style={{
                      float: "right",
                    }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ fontSize: 20, padding: 20 }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Content>
            </Layout>
          </Flex>
        </Content>
        <Footer
          style={{
            textAlign: "center",
            fontSize: 16,
          }}
        >
          Hansoftware © {new Date().getFullYear()}
        </Footer>
      </Layout>
    </div>
  );
}

export default Login;
