import { Typography, Layout } from "antd";
const { Title, Paragraph } = Typography;
const { Content, Footer } = Layout;

export default function NotFound() {
  document.title = "Page not found | Hansoftware";
  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Layout>
        <Content
          style={{
            padding: "40px 40px 0px 40px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: "#fff",
              minHeight: 200,
              padding: 24,
              borderRadius: 20,
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Title>Page not found</Title>
            <Paragraph
              style={{
                fontSize: 24,
                marginTop: 50,
              }}
            >
              Please use the navigation bar at the top of the page to go home or
              sign in.
            </Paragraph>
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
            fontSize: 16,
          }}
        >
          Hansoftware © {new Date().getFullYear()}
        </Footer>
      </Layout>
    </div>
  );
}
