import React, { useState, useEffect, createContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  GlobalSignOutCommand,
  InitiateAuthCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { clientID, cognitoClient } from "./aws-config";

const UserContext = createContext();

const User = (props) => {
  const navigate = useNavigate();

  const scheduleTokenRefresh = (expiresIn) => {
    // Schedule the token refresh a few minutes before the token actually expires
    const refreshTime = expiresIn * 1000 - 5 * 60 * 1000; // Refresh 5 minutes before expiration
    setTimeout(refreshTokens, refreshTime);
  };

  const refreshTokens = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      throw new Error("No refresh token available.");
    }

    try {
      const command = new InitiateAuthCommand({
        AuthFlow: "REFRESH_TOKEN_AUTH",
        ClientId: clientID,
        AuthParameters: {
          REFRESH_TOKEN: refreshToken,
        },
      });

      const response = await cognitoClient.send(command);
      // Extract new tokens and their expiry times
      const { IdToken, AccessToken, RefreshToken, ExpiresIn } =
        response.AuthenticationResult;
      const expirationTime = Date.now() + ExpiresIn * 1000;

      // Update tokens and expiration time in localStorage
      localStorage.setItem("accessToken", AccessToken);
      localStorage.setItem("idToken", IdToken);
      localStorage.setItem("refreshToken", RefreshToken);
      localStorage.setItem("tokenExpiration", expirationTime);

      console.log("Tokens refreshed successfully!");

      // Reschedule the next token refresh
      scheduleTokenRefresh(ExpiresIn);
    } catch (error) {
      console.error("Token refresh failed:", error);
    }
  };

  const isTokenExpired = () => {
    const expirationTime = localStorage.getItem("tokenExpiration");
    if (!expirationTime) {
      return true; // Token is considered expired if no expiration time is found
    }
    return Date.now() > expirationTime; // Check if the current time is past the expiration time
  };

  const isLoggedIn = () => {
    const idToken = localStorage.getItem("idToken");
    if (idToken && !isTokenExpired()) {
      const expirationTime = localStorage.getItem("tokenExpiration");
      const expiresIn = (expirationTime - Date.now()) / 1000; // Remaining time in seconds
      scheduleTokenRefresh(expiresIn);
      return true;
    }
    return false;
  };

  const logout = async () => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const command = new GlobalSignOutCommand({
        AccessToken: accessToken,
      });

      const response = await cognitoClient.send(command);
      console.log("User logged out", response);
      localStorage.removeItem("accessToken");
      localStorage.removeItem("idToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("tokenExpiration");
      localStorage.removeItem("email");
      localStorage.removeItem("name");
      navigate("/");
    } catch (error) {
      console.log("What happened bruh", error);
    }
  };

  return (
    <UserContext.Provider value={{ isLoggedIn, logout }}>
      {props.children}
    </UserContext.Provider>
  );
};

export { User, UserContext };
