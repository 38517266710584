import { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Icon, TopNavigation } from "@cloudscape-design/components";
import { UserContext } from "./User";

import {
  DashboardOutlined,
  HomeOutlined,
  LoginOutlined,
  LogoutOutlined,
  BarChartOutlined,
  UsergroupAddOutlined,
  UnorderedListOutlined,
  MailOutlined,
  CaretRightOutlined,
  PicLeftOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Menu, Image } from "antd";

import logo from "../Assets/hansoftwareWhite.png";
// at some point would like to have user's company logo and name in place of Hansoftware's
// only while user is logged in, and only on dashboard and program pages -- exclude landing and login
// I think it would add a nice touch to the program

export default function CustomTopNavigation() {
  const { isLoggedIn, logout } = useContext(UserContext);
  const [name, setName] = useState(localStorage.getItem("name"));
  const [email, setEmail] = useState(localStorage.getItem("email"));

  const [theme, setTheme] = useState("dark");
  const [current, setCurrent] = useState("1");
  const changeTheme = (value) => {
    setTheme(value ? "dark" : "light");
  };

  const handleNavigate = (e) => {
    if (e.key === "home" || e.key === "logo") {
      navigate("/");
    } else if (e.key === "logout") {
      logout();
      navigate("/");
    } else if (e.key === "user") {
      navigate(`/${e.key}`);
    } else {
      navigate(`/${e.key}`);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn()) {
      setName(localStorage.getItem("name"));
    }
  }, [name]);

  return (
    <Menu
      theme={theme}
      onClick={handleNavigate}
      style={{
        display: "flex",
        flex: 1,
        height: 55,
        paddingBottom: 5,
        paddingTop: 5,
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "bold",
        top: 0,
        position: "sticky",
        zIndex: 10,
      }}
      defaultOpenKeys={[]}
      selectedKeys={[]}
      mode="horizontal"
    >
      <Menu.Item key="logo">
        <Image
          src={logo}
          height={50}
          preview={false}
          style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10 }}
        />
      </Menu.Item>
      <Menu.Item
        key="home"
        icon={<HomeOutlined />}
        style={{ paddingTop: 5, paddingBottom: 5, fontSize: 16 }}
      >
        Home
      </Menu.Item>
      {!isLoggedIn() ? (
        <Menu.Item
          key="login"
          icon={<LoginOutlined />}
          style={{
            paddingTop: 5,
            paddingBottom: 5,
            fontSize: 16,
            marginLeft: "auto",
          }} // pull element to right
        >
          Sign in
        </Menu.Item>
      ) : (
        <>
          <Menu.SubMenu
            key="dash"
            icon={<DashboardOutlined />}
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              fontSize: 16,
            }}
            title={"Admin Dashboard"}
          >
            <Menu.Item key="dashboard" icon={<BarChartOutlined />}>
              Dashboard Overview
            </Menu.Item>
            <Menu.Item key="dashboard/job-tickets" icon={<PicLeftOutlined />}>
              Job Ticket Generator
            </Menu.Item>
            <Menu.Item
              key="dashboard/customer-list"
              icon={<UsergroupAddOutlined />}
            >
              Customer List
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu
            key="user"
            icon={<UserOutlined />}
            style={{
              paddingTop: 5,
              paddingBottom: 5,
              fontSize: 16,
              marginLeft: "auto",
            }}
            title={`${name}`}
          >
            <Menu.Item key="logout" icon={<LogoutOutlined />}>
              Sign out
            </Menu.Item>
          </Menu.SubMenu>
        </>
      )}
    </Menu>
  );
}
